import { Stepper } from "react-form-stepper";
import UploadFile from "../../../components/ImportLeads/uploadfile";
import { useEffect, useState } from "react";
import MatchColumns from "../../../components/ImportLeads/MatchColumns";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  excelUploadEndPoint,
  importLeadDataEndPoint,
} from "../../../service/api";
import {
  getAllLeadsListData,
  postAxiosWithToken,
} from "../../../service/apiservice";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
 
import ValidateData from "../../../components/ImportLeads/validatedata";
import Popup from "../../../components/Popup";
import circle from "../../../assets/images/Circle.svg";
import { isValidForm } from "../../../utilities/formvalidation";
 
export default function ImportLeads() {
  const [activeStep, setActiveStep] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [sheetData, setSheetData] = useState([]);
 
  const [formData, setFormData] = useState({
    lead_type: "",
    // branch: "",
    camp_name: "",
  });
 
  const [full_name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setMobileno] = useState("");
  const [city, setLocation] = useState("");
  const [branch, setBranch] = useState("");
  const [source, setSource] = useState("");
  const [jsonlist, setJsonData] = useState([]);
  const [headerlist, setHeaderList] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({ full_name: '', email: '', phone_number: '', city: '', branch:'', source: '' });
  
  const openPopup = () => {
    setPopupOpen(true);
  };
 
  const closePopup = () => {
    setPopupOpen(false);
    navigate("/leads/all-leads");
  };
 
  const navigate = useNavigate();
 
  useEffect(() => {
    if (headerlist.length > 0) {
      setName(headerlist[0]);
      setEmail(headerlist[1]);
      setMobileno(headerlist[2]);
      setLocation(headerlist[3]);
      setBranch(headerlist[4]);
      setSource(headerlist[5]);
    }
  }, [headerlist]);
 
  useEffect(() => {
    // Accessing key-value pairs using Object.entries
    var newArray = [];
 
    if (sheetData.length > 0) {
      Object.entries(sheetData[0])?.forEach(([key, value]) => {
        newArray.push(key);
      });
    }
 
    setHeaderList(newArray);
  }, [sheetData]); // Run the effect when myState changes
 
 
 
 
 
  const handleStepChange = async () => {
    if (activeStep === 0) {
      // Perform validation for the first step (upload file)
      if (
        uploadedFile &&
        formData.lead_type &&
        formData.camp_name 
        // formData.branch
      ) {
        setActiveStep(1);
        await uploadfiles();
      } else {
        toast.error("Please fill in all required fields for the first step.");
      }
    } else if (activeStep === 1) {
     if(isValidForm(selectedOptions)){
      setActiveStep(2);
      await uploadxlSheet();
     }else {
      toast.error("Please select a header for all columns in the second step.");
    }
 
    } else {
      // Perform validation for the third step (validate data)
      if (jsonlist.length > 0) {
        // Perform any necessary actions for the third step
        // ...
        // If needed, you can navigate to another page or display a success message
      } else {
        toast.error("Please validate data for the third step.");
      }
    }
  };
 
 
 
 
 
 
 
 
 
  const uploadxlSheet = async () => {
    let leadType = document.getElementById("lead_type").value;
    let campName = document.getElementById("camp_name").value;
    // let branch = document.getElementById("branch").value;
 
    // don't change key values
    let header = {
      name: full_name,
      email: email,
      mobile: phone_number,
      location: city,
      branch: branch,
      source: source,
    };
  
    let formBody = new FormData();
    formBody.set("lead_type", leadType);
    formBody.set("camp_name", campName);
    // formBody.set("branch", branch);
    formBody.set("file", uploadedFile);
    formBody.set("header_names", JSON.stringify(header));
 
    var response = await postAxiosWithToken({
      url: excelUploadEndPoint,
      body: formBody,
    });
 
    if (response !== null) {
      // dispatch(setIsLoading(true))
      toast.success(response.message);
      var templist = []
      for (var i of response.list.data_list) {
        var dictData = i;
        dictData['branch_error'] = "";
        templist.push(dictData)
      }
      setJsonData(templist);
    }
  };
 
  const uploadfiles = async () => {
    // ... (your existing code)
 
    // Use SheetJS to read the Excel file
    let reader = new FileReader();
 
    reader.onload = function (e) {
      // Parse the Excel file content
      let data = new Uint8Array(e.target.result);
      let workbook = XLSX.read(data, { type: "array" });
 
      // Assuming you want to read the first sheet
      let sheetName = workbook.SheetNames[0];
      let sheet = workbook.Sheets[sheetName];
 
      // Now you can access the data in the sheet
      let sheetData = XLSX.utils.sheet_to_json(sheet);
      setSheetData(sheetData);
      // Perform further actions with the sheet data
    };
    reader.readAsArrayBuffer(uploadedFile);
  };
 
  const FinishButton = () => {
    var isValidData = false;
    let jsonValues = [...jsonlist]
    for (var i in jsonValues) {
     
      if (String(jsonValues[i].Branch).length > 0 && String(jsonValues[i].Branch) != null && String(jsonValues[i].Branch) != undefined) {
        isValidData = true
        jsonValues[i]['branch_error'] = ""
      
      } else {
        isValidData = false
        jsonValues[i]['branch_error'] = "branch is required"
        

      }
    }

    

    if (!isValidData) {
      setJsonData(jsonValues);
    } else {

      let data = {
        "list":{
          "lead_type":formData.lead_type,
          "camp_name":formData.camp_name,
          // "branch":formData.branch,
          "data_list":jsonlist,
        }
   
      }
      // data["data_list"] = jsonlist;

      getImportLeadsData(data);
    }
  };
 
  const getImportLeadsData = async (data) => {
    var response = await postAxiosWithToken({
      url: importLeadDataEndPoint,
      body: data,
    });
 
    if (response !== null) {
      openPopup();
    }
  };
 
  return (
    <div>
      <div className="bg-[#EDF2F7] rounded-[8px]">
        <Stepper
          steps={[
            { label: "Upload file" },
            { label: "Match columns" },
            { label: "Validate data" },
          ]}
          activeStep={activeStep}
          styleConfig={{
            activeBgColor: "green",
            activeTextColor: "#fff",
            inactiveBgColor: "#fff",
            inactiveBorderColor: "green",
            inactiveTextColor: "black",
            completedBgColor: "green",
            completedTextColor: "#fff",
            size: "3em",
          }}
          className={"stepper"}
          stepClassName={"stepper__step"}
          onStepClick={handleStepChange}
        />
      </div>
 
      <div>
        <div className={`${activeStep === 0 ? "block" : "hidden"}`}>
          <UploadFile
            formData={formData}
            setFormData={setFormData}
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
          />
        </div>
        {activeStep === 1 && (
          <MatchColumns
            sheetData={sheetData}
            name={full_name}
            email={email}
            mobileno={phone_number}
            location={city}
            source={source}
            branch={branch}
            headerlist={headerlist}
            setName={setName}
            setEmail={setEmail}
            setMobileno={setMobileno}
            setLocation={setLocation}
            setBranch={setBranch}
            setSource={setSource}
            setSelectedOptions={setSelectedOptions}
            selectedOptions={selectedOptions}
          />
        )}
 
        {activeStep === 2 && (
          <ValidateData jsonlist={jsonlist} setJsonData={setJsonData} formData={formData} setFormData={setFormData} />
        )}
      </div>
 
      <div className="justify-end mb-[50px] flex gap-[30px] items-center">
        <div className="justify-end mb-[50px] flex gap-[30px] items-center">
          {activeStep === 1 || activeStep === 2 ? (
            <button
              onClick={() => {
                setActiveStep(activeStep - 1);
              }}
              id="backnav"
              className="capitalize bg-white text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-[#263238]"
            >
              Back
            </button>
          ) : null}
          {activeStep < 2 ? (
            <button
              onClick={() => {
                handleStepChange();
              }}
              className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
            >
              Next
            </button>
          ) : (
            <button
              onClick={() => FinishButton()}
              className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
            >
              Finish
            </button>
          )}
        </div>
      </div>
 
      <Popup isOpen={isPopupOpen} closeModal={closePopup}>
        <div className="text-center">
          <img className="mx-auto" src={circle} alt="" />
 
          <p className="text-[18px] mt-5">Import Finished!</p>
 
          <button
            onClick={closePopup}
            className="rounded-[8px] bg-[#0176FF] text-white py-[4px] px-[20px] mt-5 "
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}