import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { menuGroupEndPoint, menuGroupListEndPoint, menuGroupStatusEndPoint } from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { convertToDate, datetimetodate } from "../../../utilities/datetime";
import { toast } from "react-hot-toast";
import Popup from "../../../components/Popup";
import Pagination from "../../../components/Pagination";
import { DeleteButton, EditButton } from "../../../components/Actions";
import { convertToTime } from "../../../utilities/time";
import StatusForm from "./statusform";

export default function MainMenu() {

    const [menuGroupList, setMenuGroupList] = useState([]);
    const [currentMenuGroup, setCurrentMenuGroup] = useState({});
    const [formMode, setFormMode] = useState('create');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        let defaultBody = {
            active_status: status === "" ? null : JSON.parse(status),
            search: search,
            page: currentPage,
            items_per_page: perPage 
        }
        getMenuGroupListData(defaultBody)
    }, [])

    const getMenuGroupListData = async (body) => {
        var response = await postAxiosWithToken({
            // url: menuGroupListEndPoint,
            body: body
        })

        if (response !== null) {
            setMenuGroupList(response.data.list)
            setTotalPages(response.data.total_pages)
            setIsLoading(false)
        }
    }

    const onStatusChange = async (id) => {
        var response = await getAxios({
            // url: menuGroupStatusEndPoint + id + '/'
        })

        if (response !== null) {
            getMenuGroupListData()
            toast.success(response.message)
        }
    }

    const deletemenuGroup = async () => {
        var response = await deleteAxiosWithToken({
            // url: menuGroupEndPoint + currentMenuGroup.id + '/'
        })

        if (response !== null) {
            getMenuGroupListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }

    // Filter Functionalities

    const statusHandler = async (e) => {
        let statusBody = {
            active_status: e.target.value === "" ? null : JSON.parse(e.target.value),
            search: search,
            page: 1,
            items_per_page: perPage 
        }
        setStatus(e.target.value === "" ? null : JSON.parse(e.target.value))
        setCurrentPage(1)
        getMenuGroupListData(statusBody)
    }

    const searchHandler = async (e) => {
        let statusBody = {
            active_status: status === "" ? null : JSON.parse(status),
            search: e.target.value,
            page: 1,
            items_per_page: perPage 
        }
        setSearch(e.target.value)
        setCurrentPage(1)
        getMenuGroupListData(statusBody)
    }



    // Pagination

    const pageHandler = async (page) => {
        let statusBody = {
            active_status: status === "" ? null : JSON.parse(status),
            search: search,
            page: page,
            items_per_page: perPage 
        }
        setCurrentPage(page)
        getMenuGroupListData(statusBody)
    }

    const entriesHandler = async (count) => {
        let statusBody = {
            active_status: status === "" ? null : JSON.parse(status),
            search: search,
            page: 1,
            items_per_page: count 
        }
        setPerPage(count)
        setCurrentPage(1)
        getMenuGroupListData(statusBody)
    }

    return (
        <>
            <div>
                <div>
                    <StatusForm getData={getMenuGroupListData} formMode={formMode} setFormMode={setFormMode} curMenuGroup={currentMenuGroup} setCurrentMenuGroup={setCurrentMenuGroup} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
                    <table className="w-full">
                        <thead className="border bg-secondary text-white">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px]">Stage Type</td>
                                <td className="p-[10px]">Created by</td>
                                <td className="p-[10px]">Created Date</td>
                                <td className="p-[10px]">Active</td>
                                <td className="p-[10px]">Action</td>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="p-[10px]"></td>
                                <td className="p-[10px]">
                                    <input 
                                    type="text"
                                    value={search}
                                    onChange={searchHandler}
                                    className="w-full border outline-none rounded-md py-[5px] px-[10px]" />
                                </td>
                                <td className="p-[10px]">
                                    <select className="w-full border bg-white outline-none rounded-md py-[7px] px-[10px]" value={status} onChange={statusHandler}>
                                        <option value={""}>All</option>
                                        <option value={true}>Active</option>
                                        <option value={false}>Inactive</option>
                                    </select>
                                </td>
                                <td className="p-[10px]"></td>
                                <td className="p-[10px]"></td>
                            </tr>
                            {
                                isLoading ? <tr className="text-center w-full">
                                <td className="text-center border" colSpan={5}>Loading...</td>
                            </tr> :
                            menuGroupList.length === 0 ? <tr><td className="text-center border" colSpan={7}>No Data</td></tr> :
                            menuGroupList.map((item, index) => (
                                    <tr key={item.id} className="border">
                                        <td className="p-[10px]">{index + 1}</td>
                                        <td className="p-[10px]">{item.menu_group_name}</td>
                                        <td className="p-[10px]">
                                            <div onClick={() => onStatusChange(item.id)}>
                                                <SwitchButton enabled={item.is_active} />
                                            </div>
                                        </td>
                                        <td className="p-[10px]">
                                           <p className="text-[#262831] text-[14px] fony-[500]">{convertToDate(item.created_at)}</p> 
                                           <p className="text-[#BCBDBD] text-[12px] fony-[500]">{convertToTime(item.created_at)}</p>
                                            </td>
                                        <td className="p-[10px]">
                                            <div className="flex gap-3">
                                                <div onClick={() => { setCurrentMenuGroup(item); setFormMode('update'); document.getElementById('forminit').focus() }} className="cursor-pointer hover:scale-125">
                                                    <EditButton />   
                                                </div>
                                                 <div onClick={() => { setCurrentMenuGroup(item);  setIsDeleteOpen(true) }} className="cursor-pointer hover:scale-125">
                                                 <DeleteButton />  
                                                 </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
                <div className="mb-[50px]">
                    <Pagination
                    totalPages={totalPages} 
                    currentPage={currentPage} 
                    setCurrentPage={pageHandler} 
                    entries={perPage} 
                    setEntries={entriesHandler}
                     />
                </div>
            </div>

            <div>
                <Popup title={'Delete User Role'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentMenuGroup?.menu_group_name} menu group?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deletemenuGroup() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}