import React, { useState } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import Dashboard from '../../pages/Dashboard';
import { datetimetodate } from '../../utilities/datetime';
import { useSelector } from 'react-redux';

const DatePicker1 = ({ value, handleValueChange }) => {

  return (
    <div>
      <Datepicker
        showShortcuts={true}
        showFooter={true}
        value={value}
        // displayFormat={"YYYY/MM/DD"}
        onChange={handleValueChange}
        classNames="!bg-white "
        popoverDirection="down"
      />
    </div>
  );
};

export default DatePicker1;
