import { Stepper } from "react-form-stepper";
import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  excelDropoutUploadEndPoint,
  excelUploadEndPoint,
  importDropoutDataEndPoint,
  importLeadDataEndPoint,
} from "../../../service/api";
import {
  getAllLeadsListData,
  postAxiosWithToken,
} from "../../../service/apiservice";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
 
import Popup from "../../../components/Popup";
import circle from "../../../assets/images/Circle.svg";
import { isValidForm } from "../../../utilities/formvalidation";
import DropOutUploadFile from "../../../components/DropoutImportleads/dropoutuploadfile";
import DropOutMatchColumns from "../../../components/DropoutImportleads/dropmatchcolumns";
import DropOutValidateData from "../../../components/DropoutImportleads/dropoutvalidatedata";
 
export default function ImportDropout() {
  const [activeStep, setActiveStep] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
 
  const [sheetData, setSheetData] = useState([]);
 
 
 
  const [formData, setFormData] = useState({
    dropout_type: "",
    branch: "",
    dropout_status: "",
  });
 
  const [full_name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobileno] = useState("");
  const [alternate_number, setAlternativeno] = useState("");
  const [history, setHistory] = useState("");
  const [procedure, setProceduretype] = useState("");
  const [remarks1, setRemarks1] = useState("");
  const [remarks2, setRemarks2] = useState("");
  const [city, setLocation] = useState("");
  const [source_type, setSource] = useState("");
  const [jsonlist, setJsonData] = useState([]);
  const [headerlist, setHeaderList] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({ full_name: '', mobile: '', alternate_number: '',  source_type: '', history: '',procedure:'',remarks1: '',remarks2: '' });
 
 
  const openPopup = () => {
    setPopupOpen(true);
  };
 
  const closePopup = () => {
    setPopupOpen(false);
    navigate("/all-drop-out/patient-drop-out");
  };
 
  const navigate = useNavigate();
 
  useEffect(() => {
    if (headerlist.length > 0) {
      setName(headerlist[0]);
      setMobileno(headerlist[1]);
      setAlternativeno(headerlist[2]);
      setSource(headerlist[3]);
      setHistory(headerlist[4]);
      setProceduretype(headerlist[5]);
      setRemarks1(headerlist[6]);
      setRemarks2(headerlist[7]);
    }
  }, [headerlist]);
 
  useEffect(() => {
    // Accessing key-value pairs using Object.entries
    var newArray = [];
 
    if (sheetData.length > 0) {
      Object.entries(sheetData[0])?.forEach(([key, value]) => {
        newArray.push(key);
      });
    }
 
    setHeaderList(newArray);
  }, [sheetData]); // Run the effect when myState changes
 
 
 
 
 
  const handleStepChange = async () => {
    if (activeStep === 0) {
      // Perform validation for the first step (upload file)
      if (
        uploadedFile &&
        formData.dropout_type &&
        formData.dropout_status &&
        formData.branch
      ) {
        setActiveStep(1);
        await uploadfiles();
      } else {
        toast.error("Please fill in all required fields for the first step.");
      }
    } else if (activeStep === 1) {
     if(isValidForm(selectedOptions)){
      setActiveStep(2);
      await uploadxlSheet();
     }else {
      toast.error("Please select a header for all columns in the second step.");
    }
 
    } else {
      // Perform validation for the third step (validate data)
      if (jsonlist.length > 0) {
        // Perform any necessary actions for the third step
        // ...
        // If needed, you can navigate to another page or display a success message
      } else {
        toast.error("Please validate data for the third step.");
      }
    }
  };
 
 
 
 
 
 
 
 
 
  const uploadxlSheet = async () => {
    let types = document.getElementById("dropout_type").value;
    let statusName = document.getElementById("dropout_status").value;
    let branch = document.getElementById("branch").value;
 
    // don't change key values
    let header = {
      name: full_name,
      mobile: mobile,
      alternate_number: alternate_number,
      source_type: source_type,
      history: history,
      procedure: procedure,
      remarks1: remarks1,
      remarks2: remarks2,
    };
 
    let formBody = new FormData();
    formBody.set("dropout_type", types);
    formBody.set("dropout_status", statusName);
    formBody.set("branch", branch);
    formBody.set("file", uploadedFile);
    formBody.set("header_names", JSON.stringify(header));
 
    var response = await postAxiosWithToken({
      url: excelDropoutUploadEndPoint,
      body: formBody,
    });
 
    if (response !== null) {
      // dispatch(setIsLoading(true))
      toast.success(response.message);
      setJsonData(response.list.data_list);
    }
  };
 
  const uploadfiles = async () => {
    // ... (your existing code)
 
    // Use SheetJS to read the Excel file
    let reader = new FileReader();
 
    reader.onload = function (e) {
      // Parse the Excel file content
      let data = new Uint8Array(e.target.result);
      let workbook = XLSX.read(data, { type: "array" });
 
      // Assuming you want to read the first sheet
      let sheetName = workbook.SheetNames[0];
      let sheet = workbook.Sheets[sheetName];
 
      // Now you can access the data in the sheet
      let sheetData = XLSX.utils.sheet_to_json(sheet);
      setSheetData(sheetData);
      // Perform further actions with the sheet data
    };
    reader.readAsArrayBuffer(uploadedFile);
  };
 
  const FinishButton = () => {
    let data = {
      "list":{
        "dropout_type":formData.dropout_type,
        "dropout_status":formData.dropout_status,
        "branch":formData.branch,
        "data_list":jsonlist,
      }
 
    }
    // data["data_list"] = jsonlist;
 
    getImportLeadsData(data);
  };
 
  const getImportLeadsData = async (data) => {
    var response = await postAxiosWithToken({
      url: importDropoutDataEndPoint,
      body: data,
    });
 
    if (response !== null) {
      openPopup();
    }
  };
 
  return (
    <div>
      <div className="bg-[#EDF2F7] rounded-[8px]">
        <Stepper
          steps={[
            { label: "Upload file" },
            { label: "Match columns" },
            { label: "Validate data" },
          ]}
          activeStep={activeStep}
          styleConfig={{
            activeBgColor: "green",
            activeTextColor: "#fff",
            inactiveBgColor: "#fff",
            inactiveBorderColor: "green",
            inactiveTextColor: "black",
            completedBgColor: "green",
            completedTextColor: "#fff",
            size: "3em",
          }}
          className={"stepper"}
          stepClassName={"stepper__step"}
          onStepClick={handleStepChange}
        />
      </div>
 
      <div>
        <div className={`${activeStep === 0 ? "block" : "hidden"}`}>
          <DropOutUploadFile
            formData={formData}
            setFormData={setFormData}
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
          />
        </div>
        {activeStep === 1 && (
          <DropOutMatchColumns
            sheetData={sheetData}
            name={full_name}
            mobileno={mobile}
            alternate_number={alternate_number}
            source_type={source_type}
            history={history}
            procedure={procedure}
            remarks1={remarks1}
            remarks2={remarks2}
            headerlist={headerlist}
            setName={setName}
            setMobileno={setMobileno}
            setAlternativeno={setAlternativeno}
            setSource={setSource}
            setHistory={setHistory}
            setProceduretype={setProceduretype}
            setRemarks1={setRemarks1}
            setRemarks2={setRemarks2}
            setSelectedOptions={setSelectedOptions}
            selectedOptions={selectedOptions}
          />
        )}
 
        {activeStep === 2 && (
          <DropOutValidateData jsonlist={jsonlist} setJsonData={setJsonData} />
        )}
      </div>
 
      <div className="justify-end mb-[50px] flex gap-[30px] items-center">
        <div className="justify-end mb-[50px] flex gap-[30px] items-center">
          {activeStep === 1 || activeStep === 2 ? (
            <button
              onClick={() => {
                setActiveStep(activeStep - 1);
              }}
              id="backnav"
              className="capitalize bg-white text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-[#263238]"
            >
              Back
            </button>
          ) : null}
          {activeStep < 2 ? (
            <button
              onClick={() => {
                handleStepChange();
              }}
              className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
            >
              Next
            </button>
          ) : (
            <button
              onClick={() => FinishButton()}
              className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
            >
              Finish
            </button>
          )}
        </div>
      </div>
 
      <Popup isOpen={isPopupOpen} closeModal={closePopup}>
        <div className="text-center">
          <img className="mx-auto" src={circle} alt="" />
 
          <p className="text-[18px] mt-5">Import Finished!</p>
 
          <button
            onClick={closePopup}
            className="rounded-[8px] bg-[#0176FF] text-white py-[4px] px-[20px] mt-5 "
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}