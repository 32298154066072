import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 min-w-screen">
    <div className="flex items-center justify-center ">
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
    </div>
    </div>
  );
};

export default LoadingSpinner;