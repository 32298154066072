export default function Footer() {

    return (
        <>
            <div className="sm:flex items-center justify-center px-[10px] mx-auto sm:mx-0 w-fit sm:w-full">

                <p className="text-[10px] md:text-[15px] text-center">Powered by ATTS.</p>
            </div>
        </>
    );
}