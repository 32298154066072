import { configureStore } from '@reduxjs/toolkit';
import themeConfigReducer from './themeconfigslice';
import styleConfigReducer from './styleconfigslice';
import commonSliceReducer from './commonslice';



export default configureStore({
  reducer: {
    themeConfig: themeConfigReducer,
    styleConfig: styleConfigReducer,
    common: commonSliceReducer,
  },
})