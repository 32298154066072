import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  branchListEndPoint,
  cityDropdownEndPoint,
  countryDropdownEndPoint,
  departmentListEndPoint,
  designationListEndPoint,
  locationListEndPoint,
  staffGetbyMobileNumberEndPoint,
  stateDropdownEndPoint,
  userEmailSearchEndPoint,
  userEndPoint,
  userRoleListEndPoint,
} from "../../../service/api";
import {
  getAxios,
  postAxiosWithToken,
  putAxiosWithToken,
} from "../../../service/apiservice";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { phoneRegExp } from "../../../constant";
import { toast } from "react-hot-toast";
import DropDown from "../../../components/DropDown";
import Fillter from "../../../components/Fillter";
import { Icon } from "@iconify/react";
import { isValidForm } from "../../../utilities/formvalidation";

export default function UserForm() {
  const navigate = useNavigate();

  const [formMode, setFormMode] = useState("create");
  const [currentUser, setCurrentUser] = useState({});
  const [userRoleDropdown, setUserRoleDropdown] = useState([]);
  const [branchDropdown, setBranchDropdown] = useState([]);
  
  const [selectedBranch, setSelectedBranch] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [userValue, setUserValue] = useState(null);
  const [errors, setErrors] = useState(null);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [encryptPasswordValue, setEncryptPasswordValue] = useState(true);
  const [confirmencryptPasswordValue, setConfirmEncryptPasswordValue] = useState(true);
  const [displaysearch,setDisplaysearch] = useState(1);

  const onKeyPress = (event) => {
    if (event.ctrlKey && event.key === "s") {
      event.preventDefault();
      document.getElementById("formsubmit").click();
      document.getElementById("forminit").blur();
    } else if (event.key === "Escape") {
      event.preventDefault();
      setFormMode("create");
      navigate(-1);
    }
  };

  useKeyPress(["s", "Escape"], onKeyPress);

  useEffect(() => {
    getUserRoleDropdownData();
    getBranchDropdownData();
    setFormMode(localStorage.getItem("user_form_mode"));

    if (localStorage.getItem("user_form_mode") === "update") {
      getUserDetails();
    }
  }, []);
 

  const getUserDetails = async () => {
    var response = await getAxios({
      url: userEndPoint + localStorage.getItem("user_form_id") + "/",
    });

    if (response !== null) {
      setDisplaysearch(2);
      var tempDetails = {
        first_name: response.data?.staff_details?.first_name,
        last_name: response.data?.staff_details?.last_name,
        email: response.data?.staff_details?.email,
        phone: response.data?.staff_details?.phone,
        role: response.data?.user_details.role,
        branch: response.data?.staff_details?.branch,
        branch: response.data?.staff_details?.branch,
        password: response.data?.user_details?.reset_password,

      };


      setCurrentUser(tempDetails);
      setUserValue(response.data?.user_details.role)
      setMobileNumber(response.data?.staff_details?.email)
      setPassword(response.data?.user_details?.reset_password)
      setConfirmPassword(response.data?.user_details?.reset_password)
    }
  };

  const onValueUpdate = (e) => {
    const { name, value } = e.target;
    setErrors((prevState) => ({
      ...prevState,
      [name]: value.length === 0 ? `${name} field is required` : "",
    }));
    setCurrentUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if(name === "password"){

      setPassword(e.target.value);
      setPasswordsMatch(e.target.value === confirmPassword);
    } 
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const formFields = new FormData(e.target);
    const formDataObject = Object.fromEntries(formFields.entries());

    const err = {};
    Object.entries(formDataObject).forEach(([field, value]) => {
      if (value.length === 0) {
        err[field] = `${field} field is required`;
      } else {
        err[field] = "";
      }
    });

    
    // Check password and confirm password match
    err.password = passwordsMatch ? '' : 'Passwords do not match';
    err.confirmPassword = passwordsMatch ? '' : 'Passwords do not match';

    setErrors(err);

    if (isValidForm(formDataObject)) {
  
      if (localStorage.getItem("user_form_mode") === "create") {
        createUser(currentUser);
      } else {
        if (localStorage.getItem("user_form_mode") === "update") {
        updateUser(currentUser);
        }
      }
    }

   
  };

  const getUserRoleDropdownData = async () => {
    var response = await getAxios({
      url: userRoleListEndPoint,
    });

    if (response !== null) {
      setUserRoleDropdown(response.data.list);
    }
  };

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };


  const createUser = async (body) => {
    var response = await postAxiosWithToken({
      url: userEndPoint,
      body: body,
    });

    if (response != null) {
      setDisplaysearch(1);
      // clearData()
      toast.success(response.message);
      navigate("/organization/user");
    }
  };

  const updateUser = async (body) => {
    var response = await putAxiosWithToken({
      url: userEndPoint + localStorage.getItem("user_form_id") + "/",
      body: body,
    });

    if (response != null) {
      setDisplaysearch(1);
      toast.success(response.message);
      navigate("/organization/user");
    }
  };

  // const clearData = () => {
  //     reset()
  //     setCurrentUser({
  //         first_name: "",
  //         last_name: "",
  //         email: "",
  //         phone: "",
  //         role: "",
  //         city: "",
  //         state: "",
  //         country: "",
  //         password: "",
  //         confirmPassword:"",
  //         address: "",
  //         pincode: "",
  //         aadhar_card: "",
  //         pan_card: "",
  //         location: "",
  //         branch: "",
  //         department: "",
  //         designation: "",
  //     })
  // }




  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(password === e.target.value);
  };


const emailId ={
  email:mobileNumber
}


  const handleSearch = async () => {
    var response = await postAxiosWithToken({
      url: userEmailSearchEndPoint,
      body:emailId
    });

    if (response !== null) {
      var tempDetails = {
        first_name: response.data?.first_name,
        last_name: response.data?.last_name,
        email: response.data?.email,
        phone: response.data?.phone,
        staff_id: response.data?.id,
        branch: localStorage.getItem("branch"),
      };

      setCurrentUser(tempDetails);

      //   setPatientData(response.data);
    }
  };

  return (
    <>
      <div className="mb-[50px]">
        { displaysearch === 1 ? 
        <div>
        <p className="text-gray-900 text-[19px] font-semibold mb-2">
          Staff Email Id
        </p>
        <div className="col-span-2 flex justify-between items-center px-[10px] py-[8px] bg-white ">
          <div className="flex gap-[16px]">
            <input
              type="text"
              id="mobileNumber"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              className="outline-none  text-base font-medium leading-none w-full rounded-md border border-slate-800 border-opacity-20 p-4 text-black"
              placeholder="Email Id"
            />

            <button
              onClick={handleSearch}
              className="w-[200px] capitalize bg-primary text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
            >
              <span className="flex justify-center items-center gap-[10px] ">
                <Icon
                  className="my-auto"
                  icon="iconamoon:search-light"
                  color="white"
                />
                Search
              </span>
            </button>
          </div>
        </div>
        </div> : ""
         }
        <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">
          {formMode} User
        </p>
        <form onSubmit={onSubmit}>
          <div className="border p-[15px] rounded-xl shadow-lg">
            <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">
              User Details
            </p>
            <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Name <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="first name"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="first_name"
                  id="forminit"
                  value={currentUser?.first_name}
                  onChange={onValueUpdate}
                  disabled
                />
                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.first_name?.message}</p> */}
              </div>
              {/* <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Last Name <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  value={currentUser?.last_name}
                  placeholder="last name"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="last_name"
                  disabled
                />
                <p className="text-red-500 font-[400] text-[13px]">{errors.last_name?.message}</p>
              </div> */}
              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Email <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="email"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="email"
                  value={currentUser?.email}
                  onChange={onValueUpdate}
                  disabled
                />
                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.email?.message}</p> */}
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Phone no <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="phone"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                  name="phone"
                  value={currentUser?.phone}
                  onChange={onValueUpdate}
                  disabled
                />
                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.phone?.message}</p> */}
              </div>

              <div className="sm:my-[5px] ">
                <p className="mb-[5px] text-[15px]">
                  User Role <span className="text-red-500">*</span>
                </p>
                <select
                  placeholder="user role"
                  className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                  name="role"
                  onChange={(e) => {
                    setUserValue(e.target.value);
                    onValueUpdate(e);
                  }}
                  value={currentUser?.role}
                >
                  <option value={""}>--select user role--</option>
                  {userRoleDropdown.map((option) => (
                    <option
                      className="capitalize"
                      key={"user_role" + option.role_name}
                      value={option.id}
                    >
                      {option.role_name}
                    </option>
                  ))}
                </select>
                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.role?.message}</p> */}
              </div>

              {userValue == 5 || userValue == 6 || userValue == 7 ? (
                <div className="sm:my-[5px] ">
                  <p className="mb-[5px] text-[15px]">
                    Branch <span className="text-red-500">*</span>
                  </p>
                  <select
                    placeholder="Branch"
                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                    name="branch"
                    value={currentUser?.branch}
                    onChange={(e) => {
                      setSelectedBranch(e.target.value);
                      onValueUpdate(e);
                    }}
                  >
                    <option value={""}>--select branch--</option>
                    {branchDropdown.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        className="capitalize"
                      >
                        {option.branch_name}
                      </option>
                    ))}
                  </select>
                  {/* <p className="text-red-500 font-[400] text-[13px]">{errors.branch?.message}</p> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div
            className="border p-[15px] rounded-xl shadow-lg my-[25px]"
          >
            <p className="font-[600] text-[17px] text-primary border-b mb-4 pb-2">
              Password
            </p>
            <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
              <div className={` my-[5px]`}>
                <p className="mb-[5px] text-[15px]">
                  Password <span className="text-red-500">*</span>
                </p>
                <div className="flex border border-gray-300 rounded-lg items-center py-[7px] px-[10px]">
                  {/* <input
                    type={encryptPasswordValue ? "password" : "text"}
                    placeholder="password"
                    className="sm:mb-[5px] w-full text-[17px] outline-none"
                    name="password"
                    onChange={onValueUpdate}
                    value={currentUser?.reset_password}
                    // {...register('password')}
                  /> */}
                  
                  <input
                        type={encryptPasswordValue ? 'password' : 'text'}
                        placeholder="Password"
                        className="sm:mb-[5px] w-full text-[17px] outline-none"
                        name="password"
                        onChange={onValueUpdate}
                        value={password}
                      />

                    <div onClick={() => setEncryptPasswordValue(true)} className={`${!encryptPasswordValue ? 'block' : 'hidden'}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z" /></svg>
                                    </div>
                                    <div onClick={() => setEncryptPasswordValue(false)} className={`${encryptPasswordValue ? 'block' : 'hidden'}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="currentColor"><path d="m10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" /><path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6l-12-12l.708-.708l12 12l-.708.708z" /></g></svg>
                                    </div>
                </div>
                {errors?.password && <p style={{ color: 'red' }}>{errors?.password}</p>}
              </div>

              <div className={`my-[5px]`}>
                <p className="mb-[5px] text-[15px]">
                  Confirm Password <span className="text-red-500">*</span>
                </p>
                <div className="flex border border-gray-300 rounded-lg items-center py-[7px] px-[10px]">
                <input
                  type={encryptPasswordValue ? 'password' : 'text'}
                  placeholder="Confirm Password"
                  className="sm:mb-[5px] w-full text-[17px] outline-none"
                  name="confirmPassword"
                  onChange={handleConfirmPasswordChange}
                  value={confirmPassword}
                />

                  <div onClick={() => setConfirmEncryptPasswordValue(true)} className={`${!confirmencryptPasswordValue ? 'block' : 'hidden'}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z" /></svg>
                                    </div>
                                    <div onClick={() => setConfirmEncryptPasswordValue(false)} className={`${confirmencryptPasswordValue ? 'block' : 'hidden'}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="currentColor"><path d="m10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" /><path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6l-12-12l.708-.708l12 12l-.708.708z" /></g></svg>
                                    </div>
                  
                </div>
                {errors?.confirmPassword && <p style={{ color: 'red' }}>{errors?.confirmPassword}</p>}
              </div>
            </div>
          </div>

          <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5">
            <button
              id="backnav"
              onClick={(e) => {
                e.preventDefault();
                setFormMode("create");
                navigate(-1);
              }}
              className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
            >
              close
            </button>
            <button
              type="submit"
              id="formsubmit"
              className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
            >
              save
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
