import React, { useEffect, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { branchListEndPoint, staffEndPoint, staffListEndPoint } from "../../../service/api";
import { DeleteButton, EditButton } from "../../../components/Actions";
import { convertToDate } from "../../../utilities/datetime";
import { convertToTime } from "../../../utilities/time";
import { useNavigate } from "react-router-dom";
import CustomePagination from "../../../components/CustomPagination";
import Popup from "../../../components/Popup";
import Searchbar from "../../../components/SearchBar";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

function Staff() {
  const [staffList, setStaffList] = useState([]);
  const [currentStaff, setCurrentStaff] = useState({});
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [totalitems, setTotalitems] = useState({});

  const navigate = useNavigate();

  const { action } = useSelector((state) => state.common);

  const [body, setBody] = useState({
    search: "",
    branch: localStorage.getItem("branch"),
    page: 1,
    items_per_page: 10,
  });

  function closeDeleteModal() {
    setIsDeleteOpen(false);
  }
  useEffect(() => {
    getStaffListData();
  }, [body]);

  useEffect(() => {
    getBranchDropdownData();
  }, []);

  const getStaffListData = async () => {
    var response = await postAxiosWithToken({
      url: staffListEndPoint,
      body: body,
    });

    if (response !== null) {
      setStaffList(response.data.list);
      setTotalPages(response.data.total_pages);
      setIsLoading(false);
      setTotalitems(response.data);

    }
  };

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };

  const deleteStaff = async () => {
    var response = await deleteAxiosWithToken({
        url: staffEndPoint + currentStaff.id + '/'
    })

    if (response !== null) {
        getStaffListData()
        closeDeleteModal()
        toast.success(response.message)
    }
}

  // Filter

  const searchHandler = (e) => {
    setBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const pageHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };

  return (
    <div>

<div className="flex justify-between max-[810px]:block  ">
          <div className="flex gap-[20px] items-center h-[40px] justify-end mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">
            <div className="max-[520px]:mt-[5px] max-[520px]:w-full max-[520px]:mb-[50px] mt-1  ">
              <select
                placeholder="Branch"
                className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[15px] outline-none"
                name="branch"
                onChange={(e) => {
                  setBody((prevState) => ({
                    ...prevState,
                    branch: e.target.value,
                    page: 1,
                  }));
                }}
              >
                <option value={""}>--select branch--</option>
                {branchDropdown.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                    className="capitalize"
                  >
                    {option.branch_name}
                  </option>
                ))}
              </select>
            </div>


            <div className="h-[40px] w-[300px] max-[425px]:mt-[5px] max-[425px]:w-full ">
              <Searchbar value={body.search} handleChange={searchHandler} />
            </div>
          </div>

          <button
            onClick={() => {
              if (action.addPermit) {
              localStorage.setItem("staff_form_mode", "create");
              navigate("staff-form");
              }else{
                toast.error("You don't have permission for create")
              }
            }}


          
            className="w-[300px] mt-[20px] capitalize bg-primary text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
          >
            Add Staff 
          </button>
        </div>
      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
        

        <table className="w-full">
          <thead className="border bg-secondary text-white">
            <tr>
              <td className="p-[10px]">S.No</td>
              <td className="p-[10px] min-w-[200px]">Staff ID</td>
              <td className="p-[10px] min-w-[200px]">First Name</td>
              <td className="p-[10px] min-w-[200px]">Email</td>
              <td className="p-[10px] min-w-[200px]">Phone no</td>
              <td className="p-[10px] min-w-[200px]">Branch</td>
              <td className="p-[10px] min-w-[200px]">Department</td>
              <td className="p-[10px] min-w-[200px]">Designation</td>
              <td className="p-[10px] min-w-[200px]">Created At</td>
              <td className="p-[10px] min-w-[150px] sticky right-0 bg-secondary ">
                Actions
              </td>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr className="text-center w-full">
                <td className="text-center border" colSpan={6}>
                  Loading...
                </td>
              </tr>
            ) : staffList.length === 0 ? (
              <tr>
                <td className="text-center border" colSpan={6}>
                  No Data
                </td>
              </tr>
            ) : (
              staffList.map((item, index) => (
                <tr key={item.id} className="border">
                  <td className="p-[10px]">{index + 1}</td>
                  <td className="p-[10px] capitalize">{item.staff_id}</td>
                  <td className="p-[10px]">{item.first_name}</td>

                  <td className="p-[10px]">{item.email}</td>
                  <td className="p-[10px] capitalize">{item.phone}</td>
                  <td className="p-[10px] capitalize">{item.branch_name ? item.branch_name : '---'}</td>
                  <td className="p-[10px] capitalize">
                    {item.department_name}
                  </td>

                  <td className="p-[10px] capitalize">
                    {item.designation_name}
                  </td>
                  <td className="p-[10px]">
                    <p className="text-[#262831] text-[14px] fony-[500]">
                      {convertToDate(item.created_at)}
                    </p>
                    <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                      {convertToTime(item.created_at)}
                    </p>
                  </td>

                  <td className="p-[10px] sticky right-0 bg-white ">
                    <div className="flex gap-3 ">
                      <div
                        onClick={() => {
                          if (action.editPermit) {
                          setCurrentStaff(item);
                          localStorage.setItem("staff_form_mode", "update");
                          localStorage.setItem(
                            "staff_form_id",
                            item.id.toString()
                          );
                          navigate("staff-form");
                        } else{
                          toast.error("You don't have permission for update")
                        }
                        }}
                        className="cursor-pointer hover:scale-125"
                      >
                        <EditButton />
                      </div>

                    
                      <div
                        
                        onClick={() => {
                          if (action.deletePermit) {
                            setCurrentStaff(item);
                            setIsDeleteOpen(true);
                        } else{
                          toast.error("You don't have permission for delete")
                        }
                        }}
                        className="cursor-pointer hover:scale-125"
                      >
                        <DeleteButton />
                      </div> 
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="mb-[50px]">
        <CustomePagination
          totalPages={totalPages}
          currentPage={body.page}
          setCurrentPage={pageHandler}
          entries={body.items_per_page}
          entriesHandler={entriesHandler}
          totalitmes={totalitems.total_items}

          />
      </div>

      <div>
        <Popup
          title={"Delete User Role"}
          setIsOpen={setIsDeleteOpen}
          isOpen={isDeleteOpen}
          closeModal={closeDeleteModal}
        >
          <div className="mt-[10px]">
            <p className="font-400 text-[15px]">
              Do you want to delete the {currentStaff?.first_name} staff?
            </p>
            <div className="mt-[10px] flex gap-3 justify-end items-center">
              <button
                onClick={() => closeDeleteModal()}
                className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary"
              >
                Close
              </button>
              <button
                type="submit"
                onClick={() => { deleteStaff() }}
                className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}

export default Staff;
