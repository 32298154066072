import { Switch } from '@headlessui/react'
import { useSelector } from "react-redux";

export default function SwitchButton({ enabled = false, setEnabled = null, data }) {
  const isDark = useSelector((state) => state.themeConfig.isDark)

  return (
    <div className="">
      {
        setEnabled ? <Switch
        onChange={(value) => setEnabled(value)}
        checked={enabled}
        className={`${enabled ? 'bg-primary border border-primary' : isDark ? 'bg-primary border border-primary' : 'bg-secondary border border-gray-400'}
                                        relative inline-flex h-[21px] w-[39px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span
          aria-hidden="true"
          className={`${enabled ? 'translate-x-5 bg-white mt-0.5' : 'translate-x-1 bg-primary mt-0.5'} ${isDark ? 'bg-secondary' : 'bg-primary'}
                                            pointer-events-none inline-block h-[16px] w-[15px]  transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch> : <Switch
        checked={enabled}
        className={`${enabled ? 'bg-primary border border-primary' : isDark ? 'bg-primary border border-primary' : 'bg-secondary border border-gray-400'}
                                        relative inline-flex h-[21px] w-[39px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span
          aria-hidden="true"
          className={`${enabled ? 'translate-x-5 bg-white mt-0.5' : 'translate-x-1 bg-primary mt-0.5'} ${isDark ? 'bg-secondary' : 'bg-primary'}
                                            pointer-events-none inline-block h-[16px] w-[15px]  transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
      }
      
    </div>
  )
}
