import { useNavigate } from "react-router-dom";
import Error from "../../assets/images/errorpage.svg"

export default function Page404() {

    const navigate = useNavigate();

    return (
        <div className="px-[50px]">
            <div className="flex flex-col items-center justify-center gap-[25px] h-[100vh]">
                <div className="w-[200px] h-[200px]   ">
                    <img src={Error} alt="error-image" />
                </div>

                <div className="bg-[#5741E226] bg-opacity-[0.15] text-primary w-fit px-[20px] py-[5px] rounded-full"> 404 Error </div>

                <div className="text-[#2E2E2E] text-[28px] text-center  font-[700]">Oops... Page Not Found</div>

                <div className="text-[#767676] text-[18px] text-center font-[400]">Sorry, we can't find that page. You'll find lots to explore on the home page.</div>

                <div onClick={() => navigate('/')} className="w-fit h-fit px-[20px] py-[10px] bg-primary cursor-pointer rounded-xl text-white">Go to Home Page</div>
            </div>
        </div>
    );
}