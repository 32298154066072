export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const purchaseReqModule = 1;
export const purchaseOrderModule = 2;
export const poPaymentModule = 3;
export const instockModule = 4;

export const pendingStatus = 1;
export const approveStatus = 2;
export const deniedStatus = 3;
export const sentToSupplierStatus = 4;
export const canceledStatus = 5;
export const paidStatus = 6;
export const partiallyPaidStatus = 7;

export const utrMethod = 1;
export const cashMethod = 2;
export const upiMethod = 3;
export const accountTransferMethod = 4;
export const chequeMethod = 5;

export const paymentHistoryList = ['UTR', 'CASH', 'UPI', 'ACCOUNT TRANSFER', 'CHEQUE']

export const deliveryEntryType = 'delivery';
export const invoiceEntryType = 'invoice';

export const branchrole = [5,6,7]

export const entryid= 3;