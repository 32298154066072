import { useLocation } from 'react-router-dom';

export default function BreadCrumbs() {

    const { pathname } = useLocation();

    return (
        <>
            <div className=" px-[10px] mx-auto sm:mx-0 w-full py-[10px]">
                <p className='capitalize text-primary font-[500] text-[20px]'>{pathname.split('/')[pathname.split('/').length - 1].split('-').join(' ')}</p>

                <div className="flex gap-2 justify-center sm:justify-start text-[12px]">
                    <p>Home</p>
                    {
                        pathname.split('/')?.map((item, index) => (
                            <p key={"breadcrumbs"+index}>{item.split('-').join(' ')} /</p>
                        ))
                    }
                </div>

            </div>
        </>
    );
}