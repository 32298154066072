import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { userRoleEndPoint } from "../../../service/api";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";

export default function UserRoleForm({ getData, formMode, curUserRole, setFormMode, setCurrentUserRole }) {

    const schema = yup
        .object({
            role_name: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        getValues
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            role_name: curUserRole.role_name
        } : {
            role_name: ""
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createRole(data)
            } else {
                updateRole(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createRole = async (body) => {
        var response = await postAxiosWithToken({
            url: userRoleEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }

    const updateRole = async (body) => {
        var response = await putAxiosWithToken({
            url: userRoleEndPoint + curUserRole?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentUserRole({ role_name: '' })
    }

    return (
        <>
            <div>
                <form className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5" onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">User Role</p>
                        <input
                            type="text"
                            placeholder="user role"
                            className="mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="role_name"
                            id="forminit"
                            {...register('role_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.role_name?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 sm:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >save</button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] border border-white capitalize bg-secondary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                        >Reset</button>
                    </div>
                </form>
            </div>
        </>
    );
}