export default function Searchbar({
    value,
    handleChange,
}) {

    return (
        <div className="flex items-center rounded-lg overflow-hidden h-full w-full border border-[#26263E] border-opacity-20">
            <input
                type="text"
                value={value}
                onChange={handleChange}
                placeholder="search"
                className="outline-none h-full w-full px-[10px] "
            />

            <div className="text-secondary p-[9px] bg-[#E6E6E6] rounded-r-lg">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m21 21l-4.343-4.343m0 0A8 8 0 1 0 5.343 5.343a8 8 0 0 0 11.314 11.314Z" /></svg>
            </div>
        </div>
    );

}