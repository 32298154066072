import React, { useEffect, useState } from "react";
import { getAxios, putAxiosWithToken } from "../../service/apiservice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { creatPatientReviewEndPoint, entryDropdownListEndPoint, procedureDropdownListEndPoint } from "../../service/api";

export default function Dischargedetails({ onClose, currentstatus }) {
  const [statusData, setStatusData] = useState([]);
  const [formMode, setFormMode] = useState("update");
  const [entryTypeDropdown, setentryTypeDropdown] = useState([]);
  const [procedureTypeDropdown, setProcedureTypeDropdown] = useState([]);
  const [currentdetails, setCurrentdetails] = useState({});

  const schema = yup
    .object({

      remarks_discharge: yup.string().required(),
      remarks_admission: yup.string().required(),
      discharge_date: yup.string().required(),
      patiententry_type: yup.string().required(),
      procedure_type: yup.string().required(),
      admission_date: yup.string().required(),
      procedure_date: yup.string().required(),
      procedure_reason: yup.string().required(),
    })
    .required()


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    values:
      formMode === "update"
        ? {
          remarks_discharge: currentdetails?.remarks_discharge,
          remarks_admission: currentdetails?.remarks_admission,
          discharge_date: currentdetails?.discharge_date,
          // patiententry_type: currentdetails?.patiententry_type,
          procedure_type: currentdetails?.procedure_type,
          // admission_date: currentdetails?.admission_date,
          // procedure_date: currentdetails?.procedure_date,
          procedure_reason: currentdetails?.procedure_reason,
          address: "",
          source_type: "1",
        }
        : {
          discharge_date: "",
          // patiententry_type: "",
          procedure_type: "",
          // admission_date: "",
          // procedure_date: "",
          procedure_reason: "",
          remarks_discharge: "",
          remarks_admission: "",
          address: "",
          source_type: "",
        },
  });

  useEffect(() => {
    EntryDropdownList();
    ProcedureTypeDropdownList();
    // getDischargeDetails();

  }, [])

  const EntryDropdownList = async () => {
    var response = await getAxios({
      url: entryDropdownListEndPoint,
    });

    if (response !== null) {
      setentryTypeDropdown(response.data.list)
    }
  }



  const ProcedureTypeDropdownList = async () => {
    var response = await getAxios({
      url: procedureDropdownListEndPoint,
    });

    if (response !== null) {
      setProcedureTypeDropdown(response.data.list)
    }
  }

  const onSubmit = async (data) => {
    if (formMode === "update") {
      // updateStaff(data);
      onClose();
    } else {
    }
  };

  const [selectedEntryType, setSelectedEntryType] = useState('');

 
  const handleEntryTypeChange = (e) => {
    setSelectedEntryType(e.target.value);
  };

  // const getDischargeDetails = async () => {
  //   var id = localStorage.getItem("inpatient_form_id");

  //   var response = await getAxios({
  //     url: creatPatientReviewEndPoint + id + "/",
  //   });

  //   if (response !== null) {
  //     var tempDetails = {
  //       patiententry_type: response.data.patient_review_data?.patiententry_type,
  //       procedure_date: response.data.patient_review_data?.procedure_date,
  //       admission_date: response.data.patient_review_data?.admission_date,
  //     };
  //     setCurrentdetails(tempDetails);
  //     setPatientData(response.data.patient_personal_list);

  //   }
  // };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full h-full p-[25px]">
          <div className="grid xl:grid-cols-2 sm:grid-cols-2">
            <div className='flex justify-between'>
              <div>
                <p className='text-black leading-[25px] text-[14px] sm:text-[18px] font-[500] mt-2'>Patient Name</p>
                <p className='text-black leading-[25px] text-[14px] sm:text-[18px] font-[500] mt-2'>Mobile Number</p>
                <p className='text-black leading-[25px] text-[14px] sm:text-[18px] font-[500] mt-2'>Patient Entry Type</p>
              </div>
              <div>
                <p className='text-black leading-[25px] text-[14px] sm:text-[16px] font-[500] capitalize mt-2'>Name</p>
                <p className='text-black leading-[25px] text-[14px] sm:text-[16px] font-[500] capitalize mt-2'>Mobile Number</p>
                <p className='text-black leading-[25px] text-[14px] sm:text-[16px] font-[500] capitalize mt-2'>Entry Type</p>
              </div>
            </div>
          </div>
          <div className="grid xl:grid-cols-2 sm:grid-cols-2 gap-5 mt-5">
            {/* <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Patient Name<span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                placeholder="patient name"
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="patient_name"
                {...register('patient_name')}
              />
              <p className="text-red-500 font-[400] text-[13px]">
                {errors.patient_name?.message}
              </p>
            </div>
            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
                IP No<span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                placeholder="ip no"
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="ip_no"
                {...register('ip_no')}
              />
              <p className="text-red-500 font-[400] text-[13px]">
                {errors.ip_no?.message}
              </p>
            </div> */}
            {/* <div className="sm:my-[5px] ">
              <p className="mb-[5px] text-[15px]">
                Patient Entry Type<span className="text-red-500">*</span>
              </p>
              <select
                placeholder="patient_entry_type"
                className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                name="patiententry_type"
                {...register('patiententry_type')}
                onChange={handleEntryTypeChange}
                value={selectedEntryType}  >

                <option value={""}>--Select Entry Type--</option>
                {
                  entryTypeDropdown.map((option) => (
                    <option className="capitalize" key={"entry_name" + option.entry_name} value={option.id}>{option.entry_name}</option>
                  ))

                }
              </select>
              <p className="text-red-500 font-[400] text-[13px]">
                {errors.patiententry_type?.message}
              </p>
            </div> */}
            <div className="sm:my-[5px] ">
              <p className="mb-[5px] text-[15px]">
                Procedure Type<span className="text-red-500">*</span>
              </p>
              <select
                placeholder="fees type"
                className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                name="procedure_type"
                onChange={handleEntryTypeChange}
                {...register('procedure_type')}
              >
                <option value={""}>--select Procedure Type--</option>
                {
                  procedureTypeDropdown.map((option) => (
                    <option className="capitalize" key={"procedure_name" + option.procedure_name} value={option.id}>{option.procedure_name}</option>
                  ))
                }
              </select>
              <p className="text-red-500 font-[400] text-[13px]">
                {errors.procedure_type?.message}
              </p>
            </div>
            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Admission Date <span className="text-red-500">*</span>
              </p>
              <input
                type="date"
                placeholder="Admission Date "
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="admission_date"
              // {...register('admission_date')}
              />
              {/* <p className="text-red-500 font-[400] text-[13px]">
                {errors.admission_date?.message}
              </p> */}
            </div>
            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Procedure Date <span className="text-red-500">*</span>
              </p>
              <input
                type="date"
                placeholder="Procedure Date "
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="procedure_date"
              // {...register('procedure_date')}
              />
              {/* <p className="text-red-500 font-[400] text-[13px]">
                {errors.procedure_date?.message}
              </p> */}
            </div>
            <div className="my-[5px]">
              <p className="mb-[5px] text-[15px]">
                Procedure Reason<span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                placeholder="Procedure Reason"
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="procedure_reason"
                {...register('procedure_reason')}
              />
              <p className="text-red-500 font-[400] text-[13px]">
                {errors.procedure_reason?.message}
              </p>
            </div>
          </div>
          <div className="grid xl:grid-cols-1 sm:grid-cols-1">
            <p className="mt-5 text-black text-[14px] font-500">
              <label>Remark(admission)</label>
            </p>
            <textarea
              className="mt-5 border-2 border-black-500 outline-none p-1"
              id="remarks_admission"
              name="remarks_admission"
              rows="4"
              cols="50"
              {...register("remarks_admission")}
            ></textarea>

            <p className="text-red-500 font-[400] text-[13px]">
              {errors.remarks_admission?.message}
            </p>
          </div>
          <div className='border border-gray-300 mt-[25px]'></div>

          <div className="mt-[20px] grid xl:grid-cols-2 sm:grid-cols-2">
            <div>
              <p className="mb-[5px] text-[15px]">
                Discharge Date<span className="text-red-500">*</span>
              </p>

              <div className="flex items-center">
                <input
                  type="datetime-local"
                  name="discharge_date"
                  className="sm:mb-[5px] border border-gray-300 w-full rounded-lg p-[10px] text-[17px] outline-none"
                  {...register("discharge_date")}
                />
              </div>
              <p className="text-red-500 font-[400] text-[13px]">
                {errors.discharge_date?.message}
              </p>
            </div>
          </div>

          <div className="grid xl:grid-cols-1 sm:grid-cols-1">
            <p className="mt-5 text-black text-[14px] font-500">
              <label>Remark(discharge)</label>
            </p>
            <textarea
              className="mt-5 border-2 border-black-500 outline-none p-1"
              id="remarks_discharge"
              name="remarks_discharge"
              rows="4"
              cols="50"
              {...register("remarks_discharge")}
            ></textarea>

            <p className="text-red-500 font-[400] text-[13px]">
              {errors.remarks_discharge?.message}
            </p>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4"
            >
              Submit
            </button>
          </div>
        </div>
      </form>

    </div>
  );
}
