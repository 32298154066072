export const datetimetoTime = (datetime) => {
    var value = datetime ? new Date(datetime) : new Date();
    var hours = value.getHours();
    var minutes = ("0" + value.getMinutes()).slice(-2);
    var seconds = ("0" + value.getSeconds()).slice(-2);
    var ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12;

    return hours + ":" + minutes + ":" + seconds + " " + ampm;
}

export const convertToTime = (dateValue) => {
    var date = dateValue ? new Date(dateValue) : new Date();
    var hours = date.getHours();
    var minutes = ("0" + date.getMinutes()).slice(-2);
    var seconds = ("0" + date.getSeconds()).slice(-2);
    var ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12;

    return hours + ":" + minutes + ":" + seconds + " " + ampm;
}