import React, { useEffect, useState } from "react";
import {
  getAxios,
  postAxiosWithToken,
  putAxiosWithToken,
} from "../../service/apiservice";
import {
  LeadTypeDataEndPoint,
  branchListEndPoint,
  dropOutDropdownEndPoint,
  excelUploadEndPoint,
  importBranchListEndPoint,
  importLeadDataEndPoint,
  statusDropdownEndPoint,
} from "../../service/api";
import DragAndDrop from "../Drog&Drop";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

export default function DropOutUploadFile({ uploadedFile, setUploadedFile, formData, setFormData }) {
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [dropouttypeDropdown, setDropOutTypeDropdown] = useState([]);
  const [statusTypeDropdown, setStatusTypeDropdown] = useState([]);

 const [campDeatilsData,setCampDeatilsData] = useState([])


 const dispatch = useDispatch();


  // console.log("excelSheetUrl", uploadedFile);

  const handleFileUpload = (file) => {
    setUploadedFile(file);
    // You can handle the file upload logic here
  };

  const schema = yup
    .object({
      dropout_type: yup.string().required(),
      dropout_status: yup.string().required(),
      branch: yup.string().required(),
      file: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
  });

  useEffect(() => {
    getBranchDropdownData();
    getDropOutDropdownData();
    getStatusDropdownData();
  }, []);

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: importBranchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };

  const getDropOutDropdownData = async () => {
    var response = await getAxios({
      url: dropOutDropdownEndPoint,
    });

    if (response !== null) {
      setDropOutTypeDropdown(response.data.list);
    }
  };
  const getStatusDropdownData = async () => {
    var response = await getAxios({
      url: statusDropdownEndPoint,
    });

    if (response !== null) {
      setStatusTypeDropdown(response.data.list);
    }
  };

 

  

  

  const handleCampDetails = (fieldName, value) => {

    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value
    }));

    
  };



  return (
    <div>
      <form id="sheetform">
        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5 mt-8">
          <div className="sm:my-[5px] ">
            <p className="mb-[5px] text-[15px]">
              Types  <span className="text-red-500">*</span>
            </p>
            <select
              placeholder="Types"
              className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
              name="dropout_type"
              id="dropout_type"
              {...register("dropout_type")}
              value={formData.dropout_type}
              onChange={(e) => handleCampDetails('dropout_type', e.target.value)}
            >
              <option value={""}>--select Types--</option>
              {dropouttypeDropdown.map((option) => (
                <option
                  className="capitalize"
                  key={"type_name" + option.type_name}
                  value={option.id}
                >
                  {option.type_name}
                </option>
              ))}
            </select>
            <p className="text-red-500 font-[400] text-[13px]">
              {errors.dropout_type?.message}
            </p>
          </div>
          <div className="sm:my-[5px] ">
            <p className="mb-[5px] text-[15px]">
              Select Branch <span className="text-red-500">*</span>
            </p>
            <select
          placeholder="Branch"
          className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
          name="branch"
          id="branch"
          {...register("branch")}
          onChange={(e) => handleCampDetails('branch', e.target.value)}
              value={formData.branch}
            >
              <option value={""}>--select branch--</option>
              {branchDropdown.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  className="capitalize"
                >
                  {option.branch_name}
                </option>
              ))}
            </select>
            <p className="text-red-500 font-[400] text-[13px]">
              {errors.branch?.message}
            </p>
          </div>

        

<div className="sm:my-[5px] ">
            <p className="mb-[5px] text-[15px]">
              Status Name  <span className="text-red-500">*</span>
            </p>
            <select
              placeholder="Status Name"
              className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
              name="dropout_status"
              id="dropout_status"
              {...register("dropout_status")}
              value={formData.dropout_status}
              onChange={(e) => handleCampDetails('dropout_status', e.target.value)}
            >
              <option value={""}>--select Status--</option>
              {statusTypeDropdown.map((option) => (
                <option
                  className="capitalize"
                  key={"status_name" + option.status_name}
                  value={option.id}
                >
                  {option.status_name}
                </option>
              ))}
            </select>
            <p className="text-red-500 font-[400] text-[13px]">
              {errors.dropout_status?.message}
            </p>
          </div>


        </div>

        <DragAndDrop
          onFileUpload={handleFileUpload}
          uploadedFile={uploadedFile}
        />
  
      </form>
    </div>
  );
}
