import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { locationEndPoint, stateDropdownEndPoint } from "../../../service/api";
import { toast } from "react-hot-toast";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useEffect, useState } from "react";

export default function LocationForm({ getData, formMode, currentLocation, setFormMode, setCurrentLocation }) {

    const [dropDown, setDropDown] = useState("");
    const [statedropdown, setStateDropdown] = useState([]);



    const schema = yup
        .object({
            location_name: yup.string().required("laction name is required"),
            state: yup.string().required("state name is required"),

        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            location_name: currentLocation.location_name,
            state: currentLocation.state
        } : {
            location_name: "",
            state:""
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createLocation(data)
            } else {
                updateLocation(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createLocation = async (body) => {
        var response = await postAxiosWithToken({
            url: locationEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }

    const updateLocation = async (body) => {
        var response = await putAxiosWithToken({
            url: locationEndPoint + currentLocation?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentLocation({ location_name: '',state:'' })
    }


    useEffect (() => {
        stateDropdownList()
      },[])



    const stateDropdownList = async () => {
        var response = await getAxios({
          url: stateDropdownEndPoint
        });

        if (response !== null) {
            setStateDropdown(response.data.list) 
        }
      }
















    return (
        <>
            <div>
                <form className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5" onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Location Name</p>
                        <input
                            type="text"
                            placeholder="location name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="location_name"
                            id="forminit"
                            {...register('location_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.location_name?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">State</p>
                        <select
                            placeholder="state"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                            name="state"
                            {...register('state')}
                        >
                            <option value={""}>--select state--</option>
                            {
                                statedropdown.map((option) => (
                                    <option key={option.id} value={option.id} className="capitalize">{option.state_name}</option>
                                ))
                            }
                        </select>
                        <p className="text-red-500 font-[400] text-[13px]">{errors.location_name?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 mt-0 mb-[20px] xl:mt-[32px] ">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >save</button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] border border-white capitalize bg-secondary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                        >Reset</button>
                    </div>
                </form>
            </div>
        </>
    );
}