import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { iconUploadEndPoint, menuGroupEndPoint } from "../../../service/api";
import { toast } from "react-hot-toast";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useState } from "react";

export default function MainMenuForm({ getData, formMode, curMenuGroup, setFormMode, setCurrentMenuGroup }) {

    const [imageUrl, setImageUrl] = useState("");


    const schema = yup
        .object({
            menu_group_name: yup.string().required(),
            icon: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            menu_group_name: curMenuGroup.menu_group_name,
            icon: curMenuGroup.icon
        } : {
            menu_group_name: "",
            icon: ""
        }
    })

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            reset()
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    const onSubmit = (data) => {
        if (data !== '') {
            if (formMode === 'create') {
                createMenuGroup(data)
            } else {
                updateMenuGroup(data)
            }
        } else {
            errors.showMessages()
        }
    }

    const createMenuGroup = async (body) => {
        var response = await postAxiosWithToken({
            url: menuGroupEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            toast.success(response.message)
        }
    }

    const updateMenuGroup = async (body) => {
        var response = await putAxiosWithToken({
            url: menuGroupEndPoint + curMenuGroup?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            reset()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        setCurrentMenuGroup({
            menu_group_name: "",
            icon: ""
        })
    }

    const uploadIcon = async (file) => {

        if (formMode === 'update') {
            var response = await putAxiosWithToken({
                url: iconUploadEndPoint + curMenuGroup.icon?.split('/')[curMenuGroup.icon?.split('/').length - 1] + "/",
                body: new FormData(document.getElementById("imageform"))
            })
    
            if (response !== null) {
    
                var data = curMenuGroup
                data['icon'] = response.icon_url
                setCurrentMenuGroup(data)
                setImageUrl(response.icon_url)
                
                toast.success(response.message)
            }
        } else {
            var response = await postAxiosWithToken({
                url: iconUploadEndPoint,
                body: new FormData(document.getElementById("imageform"))
            })
    
            if (response !== null) {
    
                var data = curMenuGroup
                data['icon'] = response.icon_url
                setCurrentMenuGroup(data)
                setImageUrl(response.icon_url)
                
                toast.success(response.message)
            }
        }
        
    }

    return (
        <>
            <div>
            <form id="imageform">
                    <input
                        id="iconupload"
                        type="file"
                        placeholder="image"
                        className=""
                        name="image"
                        onChange={(e) => uploadIcon(e.target)}
                        hidden
                    />
                </form>
                <form className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5" onSubmit={handleSubmit(onSubmit)}>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Menu Group</p>
                        <input
                            type="text"
                            placeholder="menu group"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="menu_group_name"
                            id="forminit"
                            {...register('menu_group_name')}
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.menu_group_name?.message}</p>
                    </div>
                    <div className="my-[5px]">
                        <p className="mb-[5px] text-[15px]">Icon</p>
                        <label for="iconupload" className="sm:mb-[5px] flex justify-center gap-3 items-center  border border-gray-300 rounded-lg w-full p-[10px] text-[17px]">
                            <p>Upload Icon</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 16.004V17a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-1m-8-.5v-11M15.5 8L12 4.5L8.5 8" /></svg>
                        </label>
                        <input
                            type="text"
                            placeholder="menu name"
                            className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                            name="icon"
                            value={imageUrl}
                            {...register('icon')}
                            hidden
                        />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.icon?.message}</p>
                    </div>
                    <div className="min-[423px]:flex gap-4 mt-0 sm:mt-[35px]">
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                        >save</button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); reset() }}
                            className="max-[423px]:mt-[10px] border border-white capitalize bg-secondary text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none"
                        >Reset</button>
                    </div>
                </form>
            </div>
        </>
    );
}