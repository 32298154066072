import React, { useEffect, useState } from "react";
import {
    NextReviewStatusDataGetEndPoint,
  ReviewStatusListEndPoint,
  ReviewtypeDropDownEndPoint,
  branchListEndPoint,
  getALLDatabyIdEndPoint,
  statusTypeListEndPoint,
} from "../../../../service/api";
import { getAxios, putAxiosWithToken } from "../../../../service/apiservice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { NextReviewStatusChangeEndPoint } from "../../../../service/api";
import { phoneRegExp } from "../../../../constant";

export default function NextReviweForm({ onClose, currentstatus,getData }) {
  const [statusTypeDropdown, setStatusTypeDropdown] = useState([]);
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [formMode, setFormMode] = useState("update");
  const [reviewTypeDropdown, setReviewTypeDropdown] = useState([]);
  const [reviewstatus, setReviewstatus] = useState("")


  const schema = yup
    .object({
      name: yup.string(),
      mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      review_status: yup.string().required(),
      remarks: yup.string().required(),
      review_date: yup.string(),
      patient_id:yup.string()

    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    values:
      formMode === "update"
        ? {
            name: statusData?.name,
            review_status: statusData?.review_status,
            mobile: statusData?.mobile,
            remarks: statusData?.remarks,
            review_date:statusData?.review_date,
            patient_id:statusData?.patient_id
          }
        : {
            name: "",
            review_status: "",
            mobile: "",
            remarks: "",
            review_date:"",
            patient_id:""
          },
  });

  useEffect(() => {
    getStatusDropdownData();
    getReviewDropdownData();
    getallLeadDetails();
  }, []);

  const getallLeadDetails = async () => {
    var response = await getAxios({
      url: NextReviewStatusDataGetEndPoint + currentstatus.id + "/",
    });

   

    if (response !== null) {
      var tempDetails = {
        name: response.data.patient_list.name,
        mobile: response.data?.patient_list.phone,
        review_status: response.data?.review_list.review_status,
        remarks: response.data?.remarks,
        review_date: response.data?.review_date,
        patient_id:response.data?.review_list?.patient_id
      };

      setStatusData(tempDetails);
    }
  };

  const onSubmit = async (data) => {
    if (formMode === "update") {
      updateReviewStatus(data);
      onClose();
    } else {
    }
  };

  const updateReviewStatus = async (body) => {
    var response = await putAxiosWithToken({
      url: NextReviewStatusChangeEndPoint + currentstatus.id + "/",
      body: body,
    });

    if (response != null) {
        getData()
      toast.success(response.message);

    }
  };

  const getStatusDropdownData = async () => {
    var response = await getAxios({
      url: statusTypeListEndPoint,
    });

    if (response !== null) {
      setStatusTypeDropdown(response.data.list);
    }
  };



  const getReviewDropdownData = async () => {
    var response = await getAxios({
      url: ReviewtypeDropDownEndPoint,
    });

    if (response !== null) {
      setReviewTypeDropdown(response.data.list);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full h-full p-[30px]">
          <div className="flex gap-[10px] mt-4">

          <div className="sm:my-[5px] ">
              <p className="mb-[5px] text-[15px]">Patient Id</p>

              <input
                type="text"
                name="apatient_id"
                placeholder="Patient Id"
                disabled
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                {...register("patient_id")}
              />
            </div>

            <div className="sm:my-[5px] ">
              <p className="mb-[5px] text-[15px]">Name</p>

              <input
                type="text"
                name="name"
                placeholder="Name"
                disabled

                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                {...register("name")}
              />
            </div>
          </div>

          <div className="flex gap-[10px] mt-4">

          <div className="sm:my-[5px] ">
              <p className="mb-[5px] text-[15px]">Mobile Number</p>
              <input
                type="number"
                name="mobile"
                id="mobile"
                disabled
                placeholder=" Mobile Number"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                {...register("mobile")}
              />
            </div>




          <div className="sm:my-[5px] ">
              <p className="text-black text-[18px] font-500">
                Choose a Status:<span className="text-red-500">*</span>
              </p>
              <select
                placeholder="menu group"
                className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-[250px] p-[11px] text-[17px] outline-none "
                name="review_status"
                {...register("review_status")}
                value={reviewstatus}
                onChange={(e) => {setReviewstatus(e.target.value)}}
              >
                <option value={" "}>--select status --</option>
                {reviewTypeDropdown?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.review_status_name}
                  </option>
                ))}
              </select>
            </div>

          </div>

          <div className="flex gap-[10px] mt-4">
            <div className="sm:my-[5px] ">
              <p className="mb-[5px] text-[15px]">Appointment date</p>
              <input
                type="date"
                name="review_date"
                id="review_date"
                placeholder="Review date"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-[250px] p-[10px] text-[17px] outline-none"
                {...register("review_date")}
              />
            </div>
           

           
          </div>

          <div>
            

            <p className="mt-5 text-black text-[14px] font-500">
              Remark<span className="text-red-500">*</span>
            </p>
            <textarea
              className="mt-5 border-2 border-black-500"
              id="remarks"
              name="remarks"
              rows="4"
              cols="50"
              {...register("remarks")}
            ></textarea>
          </div>
          <div className="flex gap-[10px]">
            <button
              type="submit"
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4"
            >
              SAVE
            </button>
            <div
              onClick={onClose}
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4 cursor-pointer"
            >
              CLOSE
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
