import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';


export default function Pagination({ totalPages = 1, currentPage = 1, setCurrentPage, entries, setEntries }) {
    return (
        <div className='flex justify-between min-[500px]:flex  gap-5'>
            <div className='max-[499px]:mb-[15px] flex gap-3 items-center'>
                <p>Show Entries</p>
                <input type='number' value={entries} onChange={(e) => setEntries(e.target.value)} className='border rounded-md w-[100px] font-400 text-[15px] p-[5px] outline-primary bg-white' />
            </div>
            <div className="w-[50%] flex justify-end gap-[5px]">
            <button onClick={(e) => { e.preventDefault(); setCurrentPage(1); }} className='cursor-pointer border text-black min-w-[35px] h-[35px] rounded flex items-center justify-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="currentColor" fill-rule="evenodd" d="M15.707 15.707a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 0-1.414l5-5a1 1 0 1 1 1.414 1.414L11.414 10l4.293 4.293a1 1 0 0 1 0 1.414m-6 0a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 0-1.414l5-5a1 1 0 0 1 1.414 1.414L5.414 10l4.293 4.293a1 1 0 0 1 0 1.414" clip-rule="evenodd" /></svg>
                </button>
            <ResponsivePagination
                total={totalPages}
                current={currentPage}
                onPageChange={page => setCurrentPage(page)}
                className='flex gap-[5px]'
                activeItemClassName='border border-white text-white bg-secondary'
                pageLinkClassName='w-full text-center'
                pageItemClassName='cursor-pointer bg-[#F1F1F1] text-black w-[35px] h-[35px] rounded-[8px] flex items-center justify-center '
                disabledItemClassName='border-none text-black'
                nextClassName='text-black'
                previousClassName='text-black'
                previousLabel='<'
                nextLabel='>'
            />
            <button onClick={(e) => { e.preventDefault(); setCurrentPage(totalPages); }}  className='cursor-pointer border text-black min-w-[35px] h-[35px] rounded flex items-center justify-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"><path d="M10.293 15.707a1 1 0 0 1 0-1.414L14.586 10l-4.293-4.293a1 1 0 1 1 1.414-1.414l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0" /><path d="M4.293 15.707a1 1 0 0 1 0-1.414L8.586 10L4.293 5.707a1 1 0 0 1 1.414-1.414l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0" /></g></svg>
                </button>
        </div>
        </div>
    );
}