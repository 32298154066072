// import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
// import { Fragment, useState } from 'react'
import { postAxiosWithToken } from "../../service/apiservice";
import { leadCallEndPoint } from "../../service/api";
import {
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
} from "@material-tailwind/react";
import toast from "react-hot-toast";
import { useState } from 'react';

// import toast from 'react-hot-toast'

// export default function CustomPopover({ options, leadId, customerMobile }) {

//     const callAgent = async (phone) => {
//         var response = await postAxiosWithToken({
//             url: leadCallEndPoint,
//             body: {
//                 agent_phone: phone,
//                 customer_phone: customerMobile,
//                 lead_id: leadId
//             }
//         })

//         if (response != null) {
//             toast.success("Call Initiated")
//         }
//     }
//     return (
//         <div className="w-full max-w-sm px-4">
//             <Popover className="relative">
//                 {({ open }) => (
//                     <>
//                         <Popover.Button
//                             className={`
//                 ${open ? 'text-white' : 'text-white/90'}
//                 group inline-flex items-center rounded-md bg-[#60AF89] px-3 py-2 text-base font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
//                         >
//                             <div>
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5L15 13l5 2v4a2 2 0 0 1-2 2A16 16 0 0 1 3 6a2 2 0 0 1 2-2m10 3a2 2 0 0 1 2 2m-2-6a6 6 0 0 1 6 6"/></svg>
//                             </div>
//                             <span className='mx-2'>Call</span>
//                             <ChevronDownIcon
//                                 className={`${open ? 'text-white' : 'text-white'}
//                   ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-white`}
//                                 aria-hidden="true"
//                             />
//                         </Popover.Button>
//                         <Transition
//                             as={Fragment}
//                             enter="transition ease-out duration-200"
//                             enterFrom="opacity-0 translate-y-1"
//                             enterTo="opacity-100 translate-y-0"
//                             leave="transition ease-in duration-150"
//                             leaveFrom="opacity-100 translate-y-0"
//                             leaveTo="opacity-0 translate-y-1"
//                         >
//                             <Popover.Panel className="absolute  z-10 mt-3 w-screen max-w-sm transform px-4 sm:px-0 lg:max-w-3xl">
//                                 <div className='bg-white w-fit p-[5px] rounded-md border-2 border-[#E2E8F0E3]'>
//                                 {
//                                     options.map((item,index) => (
//                                         <div onClick={() => callAgent(item.agent_phone)} className='flex items-start gap-2 bg-[#E2E8F0E3] bg-opacity-20 py-1 px-5 rounded-sm' key={item.id} >
//                                             <div className='w-[21px] h-[21px] bg-[#2B97494D] bg-opacity-5 rounded-full flex items-center justify-center'>
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 4a4 4 0 1 1 0 8a4 4 0 0 1 0-8m0 16s8 0 8-2c0-2.4-3.9-5-8-5s-8 2.6-8 5c0 2 8 2 8 2"/></svg>
//                                             </div>
//                                             <div>
//                                                 <p className='font-[400] text-[#1D1D1F] text-[15px]'>Telecaller {index+1}</p>
//                                                 <p className='font-[400] text-[#1D1D1F] text-[15px]'>{item.agent_phone}</p>
//                                             </div>
//                                         </div>
//                                     ))
//                                 }
//                                 </div>
//                             </Popover.Panel>
//                         </Transition>
//                     </>
//                 )}
//             </Popover>
//         </div>
//     )
// }

export default function CustomPopover({ options, leadId, customerMobile }) {

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };


  const callAgent = async (phone) => {
    var response = await postAxiosWithToken({
      url: leadCallEndPoint,
      body: {
        agent_phone: phone,
        customer_phone: customerMobile,
        lead_id: leadId,
      },
    });

    if (response != null) {
      toast.success("Call Initiated");
      setIsOpen(false);

    }
  };

  return (
    <Popover
      placement="bottom-start"
      handler={(e) => setIsOpen(e)}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0, y: 25 },
      }}
      open={isOpen}
     
    >
      <PopoverHandler >
        <button className="group inline-flex items-center rounded-md bg-[#60AF89] px-3 py-2 text-base font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
          <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" color='white' height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5L15 13l5 2v4a2 2 0 0 1-2 2A16 16 0 0 1 3 6a2 2 0 0 1 2-2m10 3a2 2 0 0 1 2 2m-2-6a6 6 0 0 1 6 6"/></svg>

          </div>
          <span className="mx-2 text-white">Call</span>
          <ChevronDownIcon
            className="ml-2 h-5 w-5 text-white transition duration-150 ease-in-out group-hover:text-white"
            aria-hidden="true"
          />
        </button>
      </PopoverHandler>
      <PopoverContent>
        <div className="bg-white w-fit h-[250px] overflow-y-scroll p-[5px] rounded-md border-2 border-[#E2E8F0E3]  cursor-pointer">
          {options.map((item, index) => (
            <div
              onClick={() => callAgent(item.agent_phone)}
              className="flex items-start gap-2 bg-[#E2E8F0E3] bg-opacity-20 py-1 px-5 rounded-sm"
              key={item.id}
            >
              <div className="w-[21px] h-[21px] bg-[#2B97494D] bg-opacity-5 rounded-full flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12 4a4 4 0 1 1 0 8a4 4 0 0 1 0-8m0 16s8 0 8-2c0-2.4-3.9-5-8-5s-8 2.6-8 5c0 2 8 2 8 2"
                  />
                </svg>
              </div>
              <div>
                <p className="font-[400] text-[#1D1D1F] text-[15px]">
                  Telecaller {index + 1}
                </p>
                <p className="font-[400] text-[#1D1D1F] text-[15px]">
                  {item.agent_phone}
                </p>
              </div>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}
