import React, { useEffect, useState } from "react";
import {
  NextReviewListEndPoint,
  ReviewStatusListEndPoint,
  branchListEndPoint,
  leadAgentListEndPoint,
} from "../../../service/api";
import {
  deleteAxiosWithToken,
  getAxios,
  postAxiosWithToken,
} from "../../../service/apiservice";
import { useNavigate } from "react-router-dom";
import CustomePagination from "../../../components/CustomPagination";
import Searchbar from "../../../components/SearchBar";
import DatePicker1 from "../../../components/DatePicker1";
import {
  DeleteButton,
  EditButton,
  ViewButton,
} from "../../../components/Actions";
import { convertToDate } from "../../../utilities/datetime";
import { convertToTime } from "../../../utilities/time";
import Popup from "../../../components/Popup";
import toast from "react-hot-toast";
import NextReviweForm from "./nextreviewform";
import Model from "../../../components/Model";
import Viewreview from "../Viewreview";
import { useSelector } from "react-redux";
import { Popover } from "@headlessui/react";
import CustomPopover from "../../../components/Popover";

function NextReview() {
  const [perPage, setPerPage] = useState(10);
  const [newreviewTableList, setNextreviewTableList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [currentReview, setCurrentReview] = useState({});
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [reviewTypeDropdown, setReviewTypeDropdown] = useState([]);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [currentstatus, setCurrentstatus] = useState([]);
  const [isviewOpen, setIsviewOpen] = useState(false);
  const [totalitems, setTotalitems] = useState({});
  const [agentList, setAgentList] = useState([]);

  const [body, setBody] = useState({
    search: "",
    from_date: "",
    to_date: "",
    page: 1,
    items_per_page: 10,
    branch: localStorage.getItem("branch"),
    review_status: "2",
  });
  const { action } = useSelector((state) => state.common);

  function closeStatusModal() {
    setIsStatusOpen(false);
  }

  function closeViewModal() {
    setIsviewOpen(false);
  }

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    getreviewListData();
  }, [body]);


  useEffect(()=>{
    getAgentListData();
  },[])


  const getAgentListData = async () => {
    var response = await getAxios({
      url: leadAgentListEndPoint
    })

    if (response != null) {
      setAgentList(response.data.list)
    }
  }



  const getreviewListData = async () => {
    var response = await postAxiosWithToken({
      url: NextReviewListEndPoint,
      body: body,
    });

    if (response !== null) {
      setNextreviewTableList(response.data.list);
      setTotalPages(response.data.total_pages);
      setTotalitems(response.data);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getBranchDropdownData();
    getReviewDropdownData();
  }, []);

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };

  // Filter

  const handleDateFilter = async (e) => {
    
    if (e.startDate === null && e.endDate === null) {
      setBody((prevState) => ({
        ...prevState,
        from_date: "",
        to_date: "",
        page: 1,
      }));
    } else {
      setBody((prevState) => ({
        ...prevState,
        from_date: e.startDate,
        to_date: e.endDate,
        page: 1,
      }));
    }

    setValue(e);
  };

  const searchHandler = (e) => {
    setBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const pageHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };

  const getReviewDropdownData = async () => {
    var response = await getAxios({
      url: ReviewStatusListEndPoint,
    });

    if (response !== null) {
      setReviewTypeDropdown(response.data.list);
    }
  };

  return (
    <div>
      <div className="flex gap-[20px] items-center h-[40px] justify-between mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">
        <div>
          <select
            onChange={(e) => {
              setBody((prevState) => ({
                ...prevState,
                review_status: e.target.value,
                page: 1,
              }));
            }}
            value={body.review_status}
            placeholder="Leads"
            className=" sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[5px]  text-[#1D1D1F] text-[18px] font-[500px] outline-none"
          >
            {/* <option value={""}>All Status</option> */}
            {reviewTypeDropdown?.map((items) => (
              <option
                value={items.id}
                id={items.id}
                className="text-[16px] text-[#1D1D1F] font-[400] "
              >
                {items.review_status_name}
              </option>
            ))}
          </select>
        </div>

        <div className="flex gap-[10px] max-[520px]:block max-[520px]:mt-5">
          <div className="flex gap-[10px] max-[520px]:mt-5 ">
            <div className="max-[520px]:mt-[5px] max-[520px]:w-full max-[520px]:mb-[50px]  ">
              {localStorage.getItem("branch") == 0 ? (
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg  p-[11px] text-[15px] outline-none"
                  name="branch"
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                      page: 1,
                    }));
                  }}
                >
                  <option value={""}>--select branch--</option>

                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full    ">
            <Searchbar value={body.search} handleChange={searchHandler} />
          </div>

          <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
            <DatePicker1 handleValueChange={handleDateFilter} value={value} />
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-[10px]">
        {/* <div>
          
            <button
              

              onClick={() => {
                if (action.addPermit) {
                  localStorage.setItem("reviewList_form_mode", "create");
                navigate("/review-list/review-form");
                } else {
                  toast.error("You don't have permission for create");
                }
              }}
              className="w-[200px] capitalize bg-primary text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
            >
              <span className="flex justify-center items-center gap-[10px] ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                    fill="white"
                  />
                </svg>
                Create Review
              </span>
            </button>
          
        </div> */}
      </div>

      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
        <table className="w-full">
          <thead className="border bg-secondary text-white">
            <tr>
              <td className="p-[10px]">S.No</td>
              {localStorage.getItem('calllog') == 1 ?
              <>
              <td className="p-[10px] min-w-[100px]">Call Action</td>
              <td className="p-[10px] min-w-[150px]">Call Count</td></>:""}
              <td className="p-[10px] min-w-[150px]">File No</td>
              <td className="p-[10px] min-w-[200px]">Name</td>
              <td className="p-[10px] min-w-[200px]">Status</td>
              <td className="p-[10px] min-w-[200px]">Spouse Name</td>
              <td className="p-[10px] min-w-[200px]">Mobile No</td>
              <td className="p-[10px] min-w-[200px]">Remarks</td>
              <td className="p-[10px] min-w-[200px]">Branch</td>
              <td className="p-[10px] min-w-[200px]">Review Type</td>
              <td className="p-[10px] min-w-[200px]">Source Type</td>
              <td className="p-[10px] min-w-[200px]">Lead Type</td>
              <td className="p-[10px] min-w-[200px]">Created by</td>
              <td className="p-[10px] min-w-[200px]">Next Review Date</td>
              <td className="p-[10px] min-w-[200px]">Create Date</td>
              <td className="p-[10px] min-w-[200px]">Last Update Date</td>

              <td className="p-[10px] min-w-[100px] sticky right-0 bg-secondary">
                Action
              </td>
            </tr>
          </thead>

          <tbody>
            {isLoading ? (
              <tr className="text-center w-full">
                <td className="text-center border" colSpan={5}>
                  Loading...
                </td>
              </tr>
            ) : newreviewTableList.length === 0 ? (
              <tr>
                <td className="text-center border" colSpan={5}>
                  No Data
                </td>
              </tr>
            ) : (
              newreviewTableList.map((item, index) => (
                <tr className="border">
                  <td className="p-[10px]">{item.sno}</td>
                  {localStorage.getItem('calllog') == 1 ?
                  <>
                  
                  <td className="p-[10px] capitalize">
                  <div className='w-[150px]'>
                    <Popover
                      placement="bottom-end"
                      animate={{
                        mount: { scale: 1, y: 0 },
                        unmount: { scale: 0, y: 25 },
                      }}
                    ></Popover>
                    <CustomPopover options={agentList} customerMobile={item.mobile} leadId={item.id} />
                  </div>
                </td>
                <td className="p-[10px]">{item.call_count}</td>
                </>
                  :""}
                  <td className="p-[10px]">{item.file_no}</td>
                  <td className="p-[10px] capitalize">{item.patient_name}</td>
                  <td className="p-[10px] capitalize">
                    <div className="relative">
                      <div
                        className="text-white px-[5px] py-[10px] rounded-[10px] w-[150px] cursor-pointer"
                        style={{ backgroundColor: item.status_color }}
                      >
                        <div
                          onClick={() => {
                            setCurrentstatus(item);
                            setIsStatusOpen(true);
                          }}
                          className="flex gap-[10px] justify-center items-center cursor-pointer"
                        >
                          {item.status_name}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                          >
                            <g
                              fill="none"
                              stroke="white"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            >
                              <path d="M8 13V4.5a1.5 1.5 0 0 1 3 0V12m0-.5v-2a1.5 1.5 0 0 1 3 0V12m0-1.5a1.5 1.5 0 0 1 3 0V12" />
                              <path d="M17 11.5a1.5 1.5 0 0 1 3 0V16a6 6 0 0 1-6 6h-2h.208a6 6 0 0 1-5.012-2.7L7 19c-.312-.479-1.407-2.388-3.286-5.728a1.5 1.5 0 0 1 .536-2.022a1.867 1.867 0 0 1 2.28.28L8 13M5 3L4 2m0 5H3m11-4l1-1m0 4h1" />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="p-[10px] capitalize">{item.spouse_name}</td>

                  <td className="p-[10px]">{item.patient_mobile}</td>

                 
                  <td className="p-[10px] capitalize">{item.remarks}</td>

                  <td className="p-[10px]">{item.branch_name}</td>

                  <td className="p-[10px] capitalize">{item.review_type}</td>
                  <td className="p-[10px] capitalize">{item.source_types}</td>
                  <td className="p-[10px] capitalize">{item.lead_type}</td>
                  <td className="p-[10px] capitalize">{item.created_by}</td>
                  <td className="p-[10px] capitalize">
                    {item.review_date ? item.review_date : "0000-00-00"}
                  </td>

                  <td className="p-[10px]">
                    <p className="text-[#262831] text-[14px] fony-[500]">
                      {convertToDate(
                        item.created_at ? item.created_at : "--"
                      )}
                    </p>
                    <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                      {convertToTime(
                        item.created_at ? item.created_at : "--"
                      )}
                    </p>
                  </td>
                  <td className="p-[10px]">
                    <p className="text-[#262831] text-[14px] fony-[500]">
                      { item.modified_at != "0000-00-00 00:00" ? convertToDate(item.modified_at) :"0000-00-00"}
                    </p>
                    <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                     { item.modified_at != "0000-00-00 00:00" ? convertToTime(item.modified_at) :"0000-00-00"}
                    </p>
                  </td>

                  {/* <td className="p-[10px] capitalize">
                    {item.created_by_name}
                  </td> */}


                  <td className="p-[10px] sticky right-0 bg-white">
                    <div className="flex gap-3">
                      <div
                        onClick={() => {
                          setCurrentReview(item);
                          setIsviewOpen(true);
                        }}
                        className="cursor-pointer hover:scale-125"
                      >
                        <ViewButton />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="mb-[50px]">
        <CustomePagination
          totalPages={totalPages}
          currentPage={body.page}
          setCurrentPage={pageHandler}
          entries={body.items_per_page}
          entriesHandler={entriesHandler}
          totalitmes={totalitems.total_items}
        />
      </div>

      <div>
        <Popup
          title={"Status Change"}
          setIsOpen={setIsStatusOpen}
          isOpen={isStatusOpen}
          closeModal={closeStatusModal}
        >
          <NextReviweForm
            getData={getreviewListData}
            onClose={closeStatusModal}
            currentstatus={currentstatus}
          />
        </Popup>
      </div>

      <Model
        title={"Review Details"}
        extraClassName="max-w-[80%]"
        setIsOpen={setIsviewOpen}
        isOpen={isviewOpen}
        closeModal={closeViewModal}
      >
        <Viewreview onClose={closeViewModal} currentReview={currentReview} />
      </Model>
    </div>
  );
}

export default NextReview;
