import React from 'react';

const RailwayTimeConverter = ({ railwayTime }) => {
  // Parse the input railway time string
  const [hours, minutes, seconds] = railwayTime.split(':').map(Number);

  // Create a Date object with the parsed time
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);

  // Format the time in 12-hour clock format
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  });

  return <div>{formattedTime}</div>;
};

export default RailwayTimeConverter;
