import React, { useEffect, useState } from 'react'
import { getAxios, postAxiosWithToken } from '../../../service/apiservice';
import { importBranchListEndPoint, leadCallLogEndPoint } from '../../../service/api';
import Searchbar from '../../../components/SearchBar';
import DatePicker1 from '../../../components/DatePicker1';
import { convertToTime } from '../../../utilities/time';
import CustomPagination from '../../../components/CustomPagination';
import ReactAudioPlayer from 'react-audio-player';
import RailwayTimeConverter from '../../../components/RailwayTimeConverter';


function CallLog() {

    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [allleadsTableList, setAllCallLogTableList] = useState([]);
    const [value, setValue] = useState({
      startDate: null,
      endDate: null,
    });
    const [branchDropdown, setBranchDropdown] = useState([]);
    const [totalitems, setTotalitems] = useState({});

    const [body, setBody] = useState({
        search:"",
        from_date: "",
        branch: Number(localStorage.getItem('branch')),
        to_date : "",
        page : 1,
        items_per_page : 10,
      });

    useEffect(()=>{
        getAllCallLogData(body)

    },[body])

    useEffect(()=>{
      getBranchDropdownData()
    },[])



    const getBranchDropdownData = async () => {
      var response = await getAxios({
        url: importBranchListEndPoint,
      });
  
      if (response !== null) {
        setBranchDropdown(response.data.list);
      }
    };


    const getAllCallLogData = async () => {
        var response = await postAxiosWithToken({
          url: leadCallLogEndPoint,
          body: body,
        });
    
        if (response !== null) {
        setAllCallLogTableList(response.data.list)
          setTotalPages(response.data.total_pages);
          setTotalitems(response.data);
          setIsLoading(false);
        }
      };


      const handleDateFilter = async (e) => {
        if (e.startDate === null && e.endDate === null ){
          setBody((prevState) => ({
            ...prevState,
            from_date: "",
            to_date: "",
            page: 1,
          }));
        }else{
          setBody((prevState) => ({
            ...prevState,
            from_date: e.startDate,
            to_date: e.endDate,
            page: 1,
          }));
        }
        
        setValue(e)
      };


      const searchHandler = (e) => {
        setBody((prevState) => ({
          ...prevState,
          search: e.target.value,
          page: 1,
        }));
      };

      const pageHandler = (value) => {
        setBody((prevState) => ({
          ...prevState,
          page: value,
        }));
      };
    
    const entriesHandler = (value) => {
      setBody((prevState) => ({
        ...prevState,
        items_per_page: value,
        page: 1,
      }));
    };
    
    
  return (
    <div>


<div className="flex gap-[20px] items-center h-[40px] justify-between mb-[24px] mt-[24px] max-[650px]:block max-[650px]:mb-[50px] ">
        <p className=" sm:mb-[5px] bg-white border-none p-[5px]  text-[#1D1D1F] text-[24px] font-[500px] outline-none">
          Call Log
        </p>

        <div className="flex gap-[10px] max-[520px]:mt-5 max-[575px]:block ">

        <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
                {/* <select
                  placeholder="Source"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg  p-[11px] text-[15px] outline-none"
                  name="source_types"
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      source_types: e.target.value,
                      page: 1,
                    }));
  
                  }}
                >
                  <option value={""}>--select Source--</option>
                  {sourceDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.source_name}
                    </option>
                  ))}
                </select> */}
             
            </div>

            <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
              {localStorage.getItem("branch") == 0 ? (
                <select
                  placeholder="Branch"
                  className="sm:mb-[5px] h-[40px] w-[200px] capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="branch"
                  onChange={(e) => {
                    setBody((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                      page: 1,
                    }));

                  }}
                >
                  <option value={""}>--select branch--</option>
                  {branchDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.branch_name}
                    </option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>

            <div className="h-[40px] w-[200px] max-[520px]:mt-[5px] max-[520px]:w-full    ">
          <Searchbar value={body.search} handleChange={searchHandler} />
        </div>


            

          <div className="!border !border-slate-800 !border-opacity-20 rounded-md mt-[20px] md:mt-[0px]">
            <DatePicker1 handleValueChange={handleDateFilter} value={value} />
          </div>

         
        </div>
      </div>

      {/* <div className="flex gap-[20px] items-center h-[40px] justify-end mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">
        <div className="h-[40px] w-[300px] max-[425px]:mt-[5px] max-[425px]:w-full ">
          <Searchbar value={body.search} handleChange={searchHandler} />
        </div>
      </div> */}

      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
        <table className="w-full  mt-[30px] ">
          <thead className="border bg-secondary text-white">
            <tr>
            <td className="p-[10px]">S.No</td>
              <td className="p-[10px] min-w-[200px]">Agent Phone</td>
              <td className="p-[10px] min-w-[200px]">Customer Phone</td>
              <td className="p-[10px] min-w-[200px]">Date</td>
              <td className="p-[10px] min-w-[200px]">Time</td>
              <td className="p-[10px] min-w-[200px]">Branch</td>
              <td className="p-[10px] min-w-[200px]">Call Status</td>
              <td className="p-[10px] min-w-[200px]">Customer Status</td>
              
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr className="text-center w-full">
                <td className="text-center border" colSpan={7}>
                  Loading...
                </td>
              </tr>
            ) : allleadsTableList.length === 0 ? (
              <tr>
                <td className="text-center border" colSpan={7}>
                  No Data
                </td>
              </tr>
            ) : (
                allleadsTableList.map((item, index) => (
                <tr key={item.id} className="border">
                  <td className="p-[10px]">{item.sno}</td>
                  <td className="p-[10px] capitalize">{item.agent_phone}</td>
                  <td className="p-[10px] capitalize">{item.customer_phone}</td>
                  <td className="p-[10px] capitalize">{item.date}</td>
                  {/* <td className="p-[10px] capitalize">{convertToTime(item.time)}</td> */}
                  <td className="p-[10px] capitalize"><RailwayTimeConverter railwayTime={item.time}/></td>
                  <td className="p-[10px] capitalize">{item.branch_name}</td>
                  <td className="p-[10px] capitalize">{item.call_status}</td>
                  <td className="p-[10px] capitalize"><ReactAudioPlayer
  src={item.call_recording}
  controls
/></td>
                  
                 

 

                  

                  
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>


      <div className="mb-[50px]">
<CustomPagination
  totalPages={totalPages}
  currentPage={body.page}
  setCurrentPage={pageHandler}
  entries={body.items_per_page}
  entriesHandler={entriesHandler}
  totalitmes={totalitems.total_items}
/>
      </div>
      
    </div>
  )
}

export default CallLog;
