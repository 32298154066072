import Dischargedetails from "../components/Dischargedetails"
import Confirmed from "../pages/Appoinment/confirmed"
import Missed from "../pages/Appoinment/missed"
import Resheduled from "../pages/Appoinment/rescheduled"
import Visited from "../pages/Appoinment/visited"
import CallLog from "../pages/Callmanagement/Calllog"
import Missedcalles from "../pages/Callmanagement/Missedcalls"
import Dashboard from "../pages/Dashboard"
import CancerLead from "../pages/cancerleads"
import AllDropOut from "../pages/dropout/alldropout"
import ImportDropout from "../pages/dropout/importdropout"
import AllLeads from "../pages/leads/allleads"
import DropOut from "../pages/leads/dropout"
import ImportLeads from "../pages/leads/importleads"
import Incomingappointment from "../pages/leads/incomingappointment"
import NewLeads from "../pages/leads/newleads"
import Branchtransfer from "../pages/leads/transfer"
import Notification from "../pages/notification"
import Branch from "../pages/organization/branches"
import Department from "../pages/organization/department"
import Designation from "../pages/organization/designation"
import Locations from "../pages/organization/locations"
import Staff from "../pages/organization/staff"
import StaffForm from "../pages/organization/staff/staffform"
import User from "../pages/organization/user"
import UserForm from "../pages/organization/user/userform"
import Allpatient from "../pages/patient/allpatient"
import Createpatient from "../pages/patient/allpatient/createpatient"
import Dischargepatient from "../pages/patient/dischargepatient"
import Inpatient from "../pages/procedure/In-patient"
import Allprocedure from "../pages/procedure/allprocedure"
import Createprocedures from "../pages/procedure/allprocedure/Createprocedures"
import Apartmentpatient from "../pages/procedure/appartment-patient"
import Canclepatient from "../pages/procedure/cancel-patient"
import Outpatient from "../pages/procedure/outpatient"
import Review from "../pages/reviewlist"
import NextReview from "../pages/reviewlist/nextreview"
import Reviewform from "../pages/reviewlist/reviewform"
import MainMenu from "../pages/settings/mainmenu"
import MenuPermission from "../pages/settings/menupermission"
import Status from "../pages/settings/status"
import SubMenu from "../pages/settings/submenu"
import UserRole from "../pages/settings/userrole"



export const AddedAuthenticatedRoutes = [
    {
        path: "/dashboard",
        component: <Dashboard />
    },
    {
        path: "/organization/staff/staff-form",
        component: <StaffForm />
    },
    {
        path: "/organization/user/user-form",
        component: <UserForm />
    },
    {
        path: "/all-patient/create-patient",
        component: <Createpatient />
    },
    {
        path: "/all-procedure/create-procedure",
        component: <Createprocedures />
    },
    {
        path: "/review-list/review-form",
        component: <Reviewform />
    },
    {
        path: "/components/Dischargedetails",
        component: <Dischargedetails />
    },
    {
        path: "/call-management/call-log",
        component: <CallLog />
    },
    {
        path: "all-patient/:fromDate/:toDate",
        component: <Allpatient />
    },
    
    
    {
        path: "review-list/:fromDate/:toDate/",
        component: <Review />
    },
    {
        path: "in-patient/:fromDate/:toDate/",
        component: <Inpatient />
    },
    {
        path: "all-procedure/:fromDate/:toDate/",
        component: <Allprocedure />
    },
    {
        path: "/patient/discharge-patient/:fromDate/:toDate/",
        component: <Dischargepatient />
    },
    {
        path: "all-leads/:fromDate/:toDate/",
        component: <AllLeads />
    },
    {
        path: "/call-management/missed-call",
        component: <Missedcalles />
    },
    {
        path: "/patient/discharge-patient",
        component: <Dischargepatient />
    },
    {
        path: "/procedure/treatment-cancel",
        component: <Canclepatient />
    },
    {
        path: "/notifications/notification",
        component: <Notification />
    },
    
   
]


export const AuthenticatedComponents =  [
    {
        path: "user-permission",
        component: <MenuPermission />
    },
    {
        path: "menu-group",
        component: <MainMenu />
    },
    {
        path: "user-role",
        component: <UserRole />
    },
    {
        path: "menu",
        component: <SubMenu />
    },
    {
        path: "status",
        component: <Status />
    },


    {
        path: "location",
        component: <Locations />
    },
    {
        path: "department",
        component: <Department />
    },
    {
        path: "designation",
        component: <Designation />
    },
    {
        path: "branch",
        component: <Branch />
    },
    {
        path: "staff",
        component: <Staff />
    },
    {
        path: "user",
        component: <User />
    },
    {
        path: "all-leads",
        component: <AllLeads />
    },
    {
        path: "import-leads",
        component: <ImportLeads />
    },
    {
        path: "all-patient",
        component: <Allpatient />
    },
    {
        path: "all-procedure",
        component: <Allprocedure />
    },
    {
        path: "in-patient",
        component: <Inpatient />
    },
    {
        path: "out-patient",
        component: <Outpatient />
    },
    {
        path: "incoming-leads",
        component: <Incomingappointment />
    },
    {
        path: "review-list",
        component: <Review />
    },
    {
        path: "apartment-patient",
        component: <Apartmentpatient />
    },
    {
        path: "new-leads",
        component: <NewLeads />
    },
    {
        path: "drop-out",
        component: <DropOut />
    },
    {
        path: "next-review",
        component: <NextReview />
    },
    {
        path: "re-schedule",
        component: <Resheduled />
    },
    {
        path: "confirmed",
        component: <Confirmed />
    },
    {
        path: "missed",
        component: <Missed />
    },
    {
        path: "branch-transfer",
        component: <Branchtransfer />
    },
    {
        path: "import-patient",
        component: <ImportDropout />
    },
    {
        path: "patient-drop-out",
        component: <AllDropOut />
    },
    {
        path: "enquiry-lead",
        component: <CancerLead />
    },
    
    {
        path: "visited",
        component: <Visited />
    },
    

   

]