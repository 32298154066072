import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ReviewListEndPoint, procedureDropdownListEndPoint, procedureCreateEndpoint, entryDropdownprocedureListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { entryid } from "../../../constant";


function Createprocedures() {

    const navigate = useNavigate();

    const [entryTypeDropdown, setentryTypeDropdown] = useState([]);
    const [procedureTypeDropdown, setProcedureTypeDropdown] = useState([]);

    const [formMode, setFormMode] = useState('create');
    const [mobileNumber, setMobileNumber] = useState('');
    const [patientData, setPatientData] = useState({});
    const [currentProcedure, setCurrentProcedure] = useState({});
    const [currentProcedure1, setCurrentProcedure1] = useState("");
    const [patientid, setPatientid] = useState("");
    const [dispsearch, setdispsearch] = useState(1); //1- show , 2- hide

    const [selectedEntryType, setSelectedEntryType] = useState('');

    const handleEntryTypeChange = (e) => {
        setSelectedEntryType(e.target.value);
    };



    const schema = yup.object({
        patientid: yup.string(),
        procedure_reason: yup.string(),
        remarks: yup.string(),
        procedure_type: yup.string().required(),
        procedure_date: yup.string(),
        admission_date: yup.string().required(),
        entry_type: yup.string().required(),
        // ip_no: yup.string().when('entry_type', {
        //     is: (value) => value === '3',
        //     then: yup.string().required(),
        //     otherwise: yup.string().notRequired()
        // })
        // ip_no: parseInt(selectedEntryType) === parseInt(entryid) ? yup.string().required() : yup.string(),
        procedure_othertype: yup.string(),
        
    }).required();



    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setError,
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            patientid: patientid,
            // patientid: currentProcedure?.patientid,
            procedure_reason: currentProcedure?.procedure_reason,
            remarks: currentProcedure?.remarks,
            procedure_type: currentProcedure?.procedure_type,
            procedure_date: currentProcedure?.procedure_date,
            admission_date: currentProcedure?.admission_date,
            ip_no: currentProcedure?.ip_no,
            entry_type: currentProcedure?.entry_type,
            procedure_othertype: currentProcedure?.procedure_othertype,
           
        } : {

            // procedure_reason: "",
            // remarks: "",
            // procedure_type: "",
            // procedure_date: "",
            // admission_date: "",
            // ip_no: "",
            // entry_type: ''
           
        }
    })

    useEffect(() => {
        EntryDropdownList();
        ProcedureTypeDropdownList();
        setFormMode(localStorage.getItem("procedure_form_mode"));

        if (localStorage.getItem("procedure_form_mode") === "update") {
            getInpatientdetails();
        }
    }, [])

    const EntryDropdownList = async () => {
        var response = await getAxios({
            url: entryDropdownprocedureListEndPoint,
        });

        if (response !== null) {
            setentryTypeDropdown(response.data.list)
        }
    }

    const ProcedureTypeDropdownList = async () => {
        var response = await getAxios({
            url: procedureDropdownListEndPoint,
        });

        if (response !== null) {
            setProcedureTypeDropdown(response.data.list)
        }
    }


    const onSubmit = async (data) => {

        if (localStorage.getItem('procedure_form_mode') === 'create') {

            if (data['entry_type'].toString() === '3') {

            } else {
                delete data['ip_no']
            }
            data["patientid"] = currentProcedure1.id
            createprocedure(data)

        } else {
            if (data['entry_type'].toString() === '3') {

            } else {
                delete data['ip_no']
            }
            
            updateprocedure(data)
        }
    }




    const createprocedure = async (body) => {
        body['procedure_date']=body.procedure_date === ""? null:body.procedure_date 
        var response = await postAxiosWithToken({
            url: procedureCreateEndpoint,
            body: body
        })

        if (response != null) {
            // setdispsearch(1)
            clearData()
            toast.success(response.message)
            navigate('/procedure/all-procedure')
        }
    }



    const updateprocedure = async (body) => {
        body['procedure_date']=body.procedure_date === ""? null:body.procedure_date 
        var response = await putAxiosWithToken({
            url: procedureCreateEndpoint + localStorage.getItem('procedure_form_id') + "/",
            body: body
        })

        if (response != null) {
            clearData()
            setdispsearch(1)
            localStorage.removeItem('procedure_form_id')
            localStorage.setItem('procedure_form_mode', 'create')
            toast.success(response.message)
            navigate('/procedure/all-procedure')
        }
    }

    const clearData = () => {
        reset()
        setCurrentProcedure({
            patientid: "",
            procedure_reason: "",
            remarks: "",
            procedure_type: "",
            procedure_date: "",
            admission_date: "",
            ip_no: "",
            entry_type: ""
        })
    }

    const handleSearch = async (e) => {
        e.preventDefault();
        var response = await getAxios({
            url: ReviewListEndPoint + mobileNumber + "/" + 2 +"/"
        });
        if (response !== null) {
            setPatientData(response.data.list.patient_details)
            setCurrentProcedure1(response.data.list.patient_details)
           
            setPatientid(response.data.patient_details?.patientid)
        }
    }

    const getInpatientdetails = async () => {
        var id = localStorage.getItem("procedure_form_id");

        var response = await getAxios({
            url: procedureCreateEndpoint + id + "/",
        });

        if (response !== null) {
            setPatientid(response.data.procedure_list?.patient_id)

            setdispsearch(2)
            var tempDetails = {
                patientid: response.data.procedure_list?.patient_id,
                procedure_reason: response.data.procedure_list?.procedure_reason,
                admission_date: response.data.procedure_list?.in_date,
                remarks: response.data.procedure_list?.remarks,
                entry_type: response.data.procedure_list?.entry_type,
                procedure_type: response.data.procedure_list?.procedure_type,
                procedure_date: response.data.procedure_list?.procedure_date,
                ip_no: response.data.procedure_list?.ip_no,
                name: response.data.personal_list?.name,
                procedure_othertype: response.data.procedure_list?.procedure_othertype,
            };

            setCurrentProcedure(tempDetails);
            setSelectedEntryType(response.data.procedure_list?.entry_type,);
            setPatientData(response.data.personal_list);
        }
    };



    //today date


    const [defaultDate, setDefaultDate] = useState(getToday());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDefaultDate(getToday());
        }, 24 * 60 * 60 * 1000); // Update every 24 hours

        return () => clearInterval(intervalId);
    }, []);

    function getToday() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return (
        <div className="bg-[#F5F5F7] p-[12px] sm:p-[20px]">
            <p className="text-zinc-900 text-[18px] sm:text-[24px] font-medium leading-7">
                Add In-Patient Procedure Entry
            </p>
            <div className="bg-[#fff] rounded-lg p-[12px] sm:p-[24px] my-[20px]">
                <form onSubmit={handleSubmit(onSubmit)}>
                    {dispsearch == 1 ?
                        <div className="border p-[15px] rounded-xl shadow-lg flex items-center justify-evenly gap-[40px]">
                            <div className="w-4/5">
                                <p className="mb-[5px] text-[15px]"> Search Mobile Number / File no </p>
                                <input
                                    type="text"
                                    placeholder="Search Mobile Number | File No"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="mobileNumber"
                                    id="mobileNumber"
                                    value={mobileNumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                />
                            </div>
                            <div className="w-1/5 flex gap-[20px] mt-5">
                                <button onClick={handleSearch} className="bg-[#0176FF] border border-gray-300 rounded-lg text-white p-[10px]">
                                    Search
                                </button>
                            </div>
                        </div>
                        : ""
                    }

                    <div className="p-[10px]">
                        <p className='text-gray-900 text-[19px] font-semibold mt-[20px] sm:mt-[30px]'>Procedure Details</p>

                        <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 mt-[15px] gap-[25px]'>


                            <div className='flex justify-between'>
                                <div>
                                    <p className='text-neutral-400 leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2'>Patient Name</p>
                                    <p className='text-neutral-400 leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2'>Spouse Name</p>
                                    <p className='text-neutral-400 leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2'>Mobile Number</p>
                                    <p className='text-neutral-400 leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2'>Gender</p>
                                    <p className='text-neutral-400 leading-[25px] text-[14px] sm:text-[16px] font-normal mt-2'>E-Mail</p>
                                </div>
                                <div>
                                    <p className='text-black leading-[25px] text-[14px] sm:text-[16px] font-normal capitalize mt-2'>{patientData?.name!==""?patientData?.name:"Nill"}</p>
                                    <p className='text-black leading-[25px] text-[14px] sm:text-[16px] font-normal capitalize mt-2'>{patientData?.spouse_name!==""?patientData?.spouse_name:"Nill"}</p>
                                    <p className='text-black leading-[25px] text-[14px] sm:text-[16px] font-normal capitalize mt-2'>{patientData?.phone!==""?patientData?.phone:"Nill"}</p>
                                    <p className='text-black leading-[25px] text-[14px] sm:text-[16px] font-normal capitalize mt-2'>{patientData?.gendername!==""?patientData?.gendername:"Nill"}</p>
                                    <p className='text-black leading-[25px] text-[14px] sm:text-[16px] font-normal capitalize mt-2'>{patientData?.email!==""?patientData?.email:"Nill"}</p>
                                </div>
                            </div>


                            <div className='xl:col-span-2'>
                                <p className='text-gray-900 text-[19px] font-semibold'>Address Information</p>
                                <p className='text-black text-base font-normal capitalize mt-2'>{patientData?.address}</p>
                                <p className='text-black text-base font-normal capitalize mt-2'>{patientData?.cityname}</p>
                                <p className='text-black text-base font-normal capitalize mt-2'>{patientData?.statename}</p>
                                <p className='text-black text-base font-normal capitalize mt-2'>{patientData?.countryname}</p>
                            </div>
                        </div>
                        <div className='border border-gray-300 mt-[25px]'></div>
                    </div>


                    <p className=" mt-[30px] sm:mb-[5px] bg-white border-none p-[5px]  text-[#1D1D1F] text-[24px] font-[500px] outline-none">
                        Procedure Details
                    </p>

                    <div className="border p-[15px] rounded-xl shadow-lg mt-[40px]">
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            {/* <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">
                                    Patient id <span className="text-red-500">*</span>
                                </p>
                                <input
                                    type="text"
                                    placeholder="Procedure Date "
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="patient_id"
                                    {...register('patient_id')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">
                                    {errors.patient_id?.message}
                                </p>
                            </div> */}
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">
                                    Patient Entry Type<span className="text-red-500">*</span>
                                </p>
                                <select
                                    placeholder="entry type"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="entry_type"
                                    {...register('entry_type')}
                                    onChange={handleEntryTypeChange}
                                    value={selectedEntryType}  >
                                    <option value={""}>--Select Entry Type--</option>
                                    {
                                        entryTypeDropdown.map((option) => (
                                            <option className="capitalize" key={"entry_name" + option.entry_name} value={option.id}>{option.entry_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">
                                    {errors.entry_type?.message}
                                </p>
                            </div>


                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">
                                    Procedure Type<span className="text-red-500">*</span>
                                </p>
                                <select
                                    placeholder="fees type"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="procedure_type"
                                    {...register('procedure_type')}
                                >
                                    <option value={""}>--select Procedure Type--</option>
                                    {
                                        procedureTypeDropdown.map((option) => (
                                            <option className="capitalize" key={"procedure_name" + option.procedure_name} value={option.id}>{option.procedure_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">
                                    {errors.procedure_type?.message}
                                </p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">
                                    Procedure Other Type
                                </p>
                                <input
                                    type="text"
                                    placeholder="Procedure other type"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="procedure_othertype"
                                    {...register('procedure_othertype')}
                                />
                                
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">
                                    Procedure Date 
                                </p>
                                <input
                                    type="date"
                                    placeholder="Procedure Date "
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="procedure_date"
                                    defaultValue={defaultDate}
                                    {...register('procedure_date')}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">
                                    {errors.procedure_date?.message}
                                </p> */}
                            </div>

                            <div className="my-[5px]">
                                {selectedEntryType.toString() === "" ? <p className="mb-[5px] text-[15px]">Admission Date <span className="text-red-500">*</span></p> : selectedEntryType.toString() === '3' ? <p className="mb-[5px] text-[15px]">Admission Date <span className="text-red-500">*</span></p> : selectedEntryType.toString() === "4" ? <p className="mb-[5px] text-[15px]">Treatment Date <span className="text-red-500">*</span></p> : selectedEntryType.toString() === "5" ? <p className="mb-[5px] text-[15px]">Treatment Date <span className="text-red-500">*</span></p> : ""}
                                <input
                                    type="date"
                                    placeholder="Admission Date"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="admission_date"
                                    {...register('admission_date')}
                                    defaultValue={defaultDate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">
                                    {errors.admission_date?.message}
                                </p>
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">
                                    Procedure Reason
                                </p>
                                <input
                                    type="text"
                                    placeholder="Procedure Reason"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="procedure_reason"
                                    {...register('procedure_reason')}
                                />
                              
                            </div>
                            {/* {selectedEntryType.toString() === "3" ? */}
                            <div className={`${selectedEntryType.toString() === entryid.toString() ? "block" : "hidden"} my-[5px] `}>
                                <p className="mb-[5px] text-[15px]">
                                    IP No<span className="text-red-500">*</span>
                                </p>
                                <input
                                    type="text"
                                    placeholder="ip no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="ip_no"
                                    {...register('ip_no')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">
                                    {errors.ip_no?.message}
                                </p>
                            </div>
                            {/* : "" */}
                            {/* } */}

                        </div>
                        <div className='mt-[50px] grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3'>
                            <div className="col-span-2 ">
                                <p className="mb-[5px] text-[15px]">Remarks </p>
                                <div className='bg-white rounded-md border border-slate-200 p-3'>
                                    <textarea name="remarks"
                                        {...register('remarks')} className='outline-none w-full' id="" cols="30" rows="5" placeholder='Hello'></textarea>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 pt-5 pb-5">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); reset(); navigate(-1) }}
                            className="capitalize bg-secondary text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border border-primary"
                        >close</button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save</button>
                    </div>
                </form>
            </div>
        </div>




    );
}

export default Createprocedures;