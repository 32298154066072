const month = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
export const datetimetodate = (datetime, monthLength) => {
    var value = new Date(datetime)
    return value.getDate() + " " + month[value.getMonth()].slice(0,monthLength) + "," + value.getFullYear()
}

export const convertToDate = (dateValue) => {
    var date = new Date(dateValue),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}