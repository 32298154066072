import React, { useEffect, useState } from "react";
import { DeleteButton, EditButton } from "../../../components/Actions";
import { convertToTime } from "../../../utilities/time";
import { convertToDate } from "../../../utilities/datetime";
import SwitchButton from "../../../components/Switch";
import {
  deleteAxiosWithToken,
  getAxios,
  postAxiosWithToken,
} from "../../../service/apiservice";
import {
  menuEndPoint,
  menuGroupEndPoint,
  menuGroupListEndPoint,
  menuGroupStatusEndPoint,
  menuListEndPoint,
  menuStatusEndPoint,
} from "../../../service/api";
import Searchbar from "../../../components/SearchBar";
import CustomePagination from "../../../components/CustomPagination";
import Popup from "../../../components/Popup";
import toast from "react-hot-toast";
import SubMenuForm from "./submenuform";
import { useSelector } from "react-redux";

function SubMenu() {
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [formMode, setFormMode] = useState("create");
  const [curMenu, setCurrentMenu] = useState({});

  const { action } = useSelector((state) => state.common);

  const [body, setBody] = useState({
    search: "",
    active_status: "",
    page: 1,
    items_per_page: 10,
  });

  function closeDeleteModal() {
    setIsDeleteOpen(false);
  }

  useEffect(() => {
    getMenuListData();
  }, [body]);

  const getMenuListData = async () => {
    var response = await postAxiosWithToken({
      url: menuListEndPoint,
      body: body,
    });

    if (response !== null) {
      setLocationList(response.data.list);
      setTotalPages(response.data.total_pages);
      setIsLoading(false);
    }
  };

  // const statusHandler = (e) => {
  //   setBody((prevState) => ({
  //     ...prevState,
  //     active_status: e.target.value === "" ? "" : JSON.parse(e.target.value),
  //     page: 1,
  //   }));
  // };

  const searchHandler = (e) => {
    setBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const pageHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };

  const deleteMenu = async () => {
    var response = await deleteAxiosWithToken({
      url: menuEndPoint + curMenu.id + "/",
    });

    if (response !== null) {
      getMenuListData();
      closeDeleteModal();
      toast.success(response.message);
    }
  };

  const onStatusChange = async (id) => {
    var response = await getAxios({
      url: menuStatusEndPoint + id + "/",
    });

    if (response !== null) {
      if (response.status === 200) {
        getMenuListData();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  };

  return (
    <div>
      {formMode === "create" ? (
        action.addPermit ? (
          <div>
            <SubMenuForm
              curMenu={curMenu}
              setCurrentMenu={setCurrentMenu}
              formMode={formMode}
              setFormMode={setFormMode}
              getData={getMenuListData}
            />
          </div>
        ) : (
          ""
        )
      ) : action.editPermit ? (
        <div>
          <SubMenuForm
            curMenu={curMenu}
            setCurrentMenu={setCurrentMenu}
            formMode={formMode}
            setFormMode={setFormMode}
            getData={getMenuListData}
          />
        </div>
      ) : (
        ""
      )}
      <div className="flex gap-[20px] items-center h-[40px] justify-end mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">
        {/* <div className="w-[200px] max-[425px]:mt-[5px] max-[425px]:w-full">
          <select
            className="w-full border bg-white outline-none rounded-md h-[40px] "
            value={body.active_status}
            onChange={statusHandler}
          >
            <option value={""}>All</option>
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </select>
        </div> */}

        <div className="h-[40px] w-[300px] max-[425px]:mt-[5px] max-[425px]:w-full ">
          <Searchbar value={body.search} handleChange={searchHandler} />
        </div>
      </div>

      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
        <table className="w-full">
          <thead className="border bg-secondary text-white">
            <tr>
              <td className="p-[10px]">S.No</td>
              <td className="p-[10px]">Menu Name</td>
              <td className="p-[10px]">Menu Group</td>
              <td className="p-[10px]">Created At</td>
              <td className="p-[10px]">Status</td>
              <td className="p-[10px] sticky right-0 bg-secondary">Actions</td>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr className="text-center w-full">
                <td className="text-center border" colSpan={7}>
                  Loading...
                </td>
              </tr>
            ) : locationList.length === 0 ? (
              <tr>
                <td className="text-center border" colSpan={7}>
                  No Data
                </td>
              </tr>
            ) : (
              locationList.map((item, index) => (
                <tr key={item.id} className="border">
                  <td className="p-[10px]">{index + 1}</td>
                  <td className="p-[10px] capitalize">{item.menu_name}</td>
                  <td className="p-[10px] capitalize">
                    {item.menu_group_name}
                  </td>

                  <td className="p-[10px]">
                    <p className="text-[#262831] text-[14px] fony-[500]">
                      {convertToDate(item.created_at)}
                    </p>
                    <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                      {convertToTime(item.created_at)}
                    </p>
                  </td>
                  <td className="p-[10px]">
                    <div onClick={() => onStatusChange(item.id)}>
                      <SwitchButton enabled={item.is_active} />
                    </div>
                  </td>

                  <td className="p-[10px]  sticky right-0 bg-white">
                    <div className="flex gap-3">
                      <div
                        onClick={() => {
                          if (action.editPermit) {
                            setCurrentMenu(item);
                            setFormMode("update");
                          } else {
                            toast.error("You don't have permission for update");
                          }
                        }}
                        className="cursor-pointer hover:scale-125"
                      >
                        <EditButton />
                      </div>
                      <div
                        onClick={() => {
                          if (action.deletePermit) {
                            setCurrentMenu(item);
                            setIsDeleteOpen(true);
                          } else {
                            toast.error("You don't have permission for delete");
                          }
                        }}
                        className="cursor-pointer hover:scale-125"
                      >
                        <DeleteButton />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="mb-[50px]">
        <CustomePagination
          totalPages={totalPages}
          currentPage={body.page}
          setCurrentPage={pageHandler}
          entries={body.items_per_page}
          entriesHandler={entriesHandler}
        />
      </div>
      <div>
        <Popup
          title={"Delete Menu Group"}
          setIsOpen={setIsDeleteOpen}
          isOpen={isDeleteOpen}
          closeModal={closeDeleteModal}
        >
          <div className="mt-[10px]">
            <p className="font-400 text-[15px]">
              Do you want to delete the {curMenu?.menu_name} Sub Menu?
            </p>
            <div className="mt-[10px] flex gap-3 justify-end items-center">
              <button
                onClick={() => closeDeleteModal()}
                className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary"
              >
                Close
              </button>
              <button
                type="submit"
                onClick={() => {
                  deleteMenu();
                }}
                className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}

export default SubMenu;
