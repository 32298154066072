import React, { useEffect, useState } from "react";
import { DeleteButton, EditButton } from "../../../components/Actions";
import { convertToTime } from "../../../utilities/time";
import { convertToDate } from "../../../utilities/datetime";
import SwitchButton from "../../../components/Switch";
import {
  deleteAxiosWithToken,
  getAxios,
  postAxiosWithToken,
} from "../../../service/apiservice";
import {
    branchEndPoint,
    branchListEndPoint,
  branchStatusEndPoint,
  departmentEndPoint,
  departmentListEndPoint,
  departmentStatusEndPoint,
} from "../../../service/api";
import Searchbar from "../../../components/SearchBar";
import Pagination from "../../../components/Pagination";
import CustomePagination from "../../../components/CustomPagination";
import Popup from "../../../components/Popup";
import toast from "react-hot-toast";
import BranchForm from "./branchform";
import { useSelector } from "react-redux";

function Department() {
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [formMode, setFormMode] = useState("create");
  const [currentBranch, setCurrentBranch] = useState({});
  const [totalitems, setTotalitems] = useState({});

  const { action } = useSelector((state) => state.common);

  const [body, setBody] = useState({
    search: "",
    location : "",
    page: 1,
    items_per_page: 10,
    active_status: "",

  });

  function closeDeleteModal() {
    setIsDeleteOpen(false);
  }

  useEffect(() => {
    getBranchListData();
  }, [body]);

  const getBranchListData = async () => {
    var response = await postAxiosWithToken({
      url: branchListEndPoint,
      body: body,
    });

    if (response !== null) {
      setBranchList(response.data.list);
      setTotalPages(response.data.total_pages);
      setIsLoading(false);
      setTotalitems(response.data);

    }
  };

  // const statusHandler = (e) => {
  //   setBody((prevState) => ({
  //     ...prevState,
  //     active_status: e.target.value === "" ? "" : JSON.parse(e.target.value),
  //     page: 1,
  //   }));
  // };

  const searchHandler = (e) => {
    setBody((prevState) => ({
      ...prevState,
      search: e.target.value,
      page: 1,
    }));
  };

  const pageHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setBody((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };

  const deleteDepartment = async () => {
    var response = await deleteAxiosWithToken({
      url: branchEndPoint + currentBranch.id + "/",
    });

    if (response !== null) {
      getBranchListData();
      closeDeleteModal();
      toast.success(response.message);
    }
  };

  const onStatusChange = async (id) => {
    var response = await getAxios({
        url: branchStatusEndPoint + id + '/'
    })

    if (response !== null) {
        if (response.status === 200) {
          getBranchListData()
            toast.success(response.message)
        } else {
            toast.error(response.message)
        }
    }
}


  return (
    <div>
      


{
                    formMode === 'create' ? action.addPermit ? <div>
                      <BranchForm
        currentBranch={currentBranch}
        setCurrentBranch={setCurrentBranch}
        formMode={formMode}
        setFormMode={setFormMode}
        getData={getBranchListData}
      />
                    </div> : '' : action.editPermit ? <div>
                    <BranchForm
        currentBranch={currentBranch}
        setCurrentBranch={setCurrentBranch}
        formMode={formMode}
        setFormMode={setFormMode}
        getData={getBranchListData}
      />
                    </div> : ''
                }
      <div className="flex gap-[20px] items-center h-[40px] justify-end mb-[24px] mt-[24px] max-[425px]:block max-[425px]:mb-[50px] ">
        {/* <div className="w-[200px] max-[425px]:mt-[5px] max-[425px]:w-full">
          <select
            className="w-full border bg-white outline-none rounded-md h-[40px] "
            value={body.active_status}
            onChange={statusHandler}
          >
            <option value={""}>All</option>
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </select>
        </div> */}

        <div className="h-[40px] w-[300px] max-[425px]:mt-[5px] max-[425px]:w-full ">
          <Searchbar value={body.search} handleChange={searchHandler} />
        </div>
      </div>

      <div className="w-full my-[10px] mb-[50px] overflow-x-scroll rounded-lg ">
        <table className="w-full">
          <thead className="border bg-secondary text-white">
            <tr>
              <td className="p-[10px]">S.No</td>
              <td className="p-[10px] min-w-[200px]">Branch</td>
              <td className="p-[10px] min-w-[200px]">Location</td>
              <td className="p-[10px] min-w-[200px]">Short Code</td>
              <td className="p-[10px] min-w-[200px]">Status</td>
              <td className="p-[10px] min-w-[500px]">Address</td>
              <td className="p-[10px] min-w-[200px]">Created At</td>
              <td className="p-[10px] min-w-[150px] sticky right-0 bg-secondary ">Actions</td>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr className="text-center w-full">
                <td className="text-center border" colSpan={5}>
                  Loading...
                </td>
              </tr>
            ) : branchList.length === 0 ? (
              <tr>
                <td className="text-center border" colSpan={5}>
                  No Data
                </td>
              </tr>
            ) : (
              branchList.map((item, index) => (
                <tr key={item.id} className="border">
                  <td className="p-[10px]">{index + 1}</td>
                  <td className="p-[10px] capitalize">
                    {item.branch_name}
                  </td>
                  <td className="p-[10px] capitalize">
                    {item.location_name}
                  </td>
                  <td className="p-[10px] capitalize">
                    {item.short_code}
                  </td>
                  <td className="p-[10px]">
                    <div onClick={() => onStatusChange(item.id)}>
                      <SwitchButton enabled={item.is_active} />
                    </div>
                  </td>
                  <td className="p-[10px] capitalize">
                    {item.address}
                  </td>
                  <td className="p-[10px]">
                    <p className="text-[#262831] text-[14px] fony-[500]">
                      {convertToDate(item.created_at)}
                    </p>
                    <p className="text-[#BCBDBD] text-[12px] fony-[500]">
                      {convertToTime(item.created_at)}
                    </p>
                  </td>
                  

                  <td className="p-[10px] sticky right-0 bg-white ">
                    <div className="flex gap-3">
                      <div
                        

                        onClick={() => {
                          if (action.editPermit) {
                            setCurrentBranch(item);
                          setFormMode("update");
                          }
                          else {
                            toast.error("You don't have permission for update")
                        }
                        }}
                        className="cursor-pointer hover:scale-125"
                      >
                        <EditButton />
                      </div>
                      <div
                        onClick={() => {
                          if (action.deletePermit) {
                            setCurrentBranch(item);
                          setIsDeleteOpen(true);
                          }
                          else {
                            toast.error("You don't have permission for delete")
                        }
                        }}
                        className="cursor-pointer hover:scale-125"
                      >
                        <DeleteButton />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="mb-[50px]">
        <CustomePagination
          totalPages={totalPages}
          currentPage={body.page}
          setCurrentPage={pageHandler}
          entries={body.items_per_page}
          entriesHandler={entriesHandler}
          totalitmes={totalitems.total_items}

          />
      </div>
      <div>
        <Popup
          title={"Delete Branch"}
          setIsOpen={setIsDeleteOpen}
          isOpen={isDeleteOpen}
          closeModal={closeDeleteModal}
        >
          <div className="mt-[10px]">
            <p className="font-400 text-[15px]">
              Do you want to delete the {currentBranch?.branch_name}{" "}
              branch?
            </p>
            <div className="mt-[10px] flex gap-3 justify-end items-center">
              <button
                onClick={() => closeDeleteModal()}
                className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary"
              >
                Close
              </button>
              <button
                type="submit"
                onClick={() => {
                  deleteDepartment();
                }}
                className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}

export default Department;
